import React, { useEffect, useRef, useState } from "react";
import "../scss/floor-area-draw.scss";
import { FloorArea, FloorAreaLocation } from "~/shared/interfaces";
import { Inplace, InplaceContent, InplaceDisplay } from "primereact/inplace";
import { InputText } from "primereact/inputtext";
import { OverlayPanel } from "primereact/overlaypanel";
import { Button } from "~/shared/components/dcp-button";
import LanguageProvider from "~/shared/components/language-provider";

interface FloorAreaDrawProps {
  floorArea: FloorArea;
  locations: FloorAreaLocation[];
  onLocationNameChange: (location: FloorAreaLocation) => void;
}

export function FloorAreaDraw(props: FloorAreaDrawProps): JSX.Element {
  const areaContainerRef = useRef(null);

  const [loactionsPerRow, setLocationsPerRow] = useState<number>(0);
  const [hasSmallerRow, setHasSmallerRow] = useState<boolean>(false);
  const [smallerRowSize, setSmallerRowSize] = useState(0);
  const [selectedLocation, setSelectedLocation] = useState<FloorAreaLocation>({
    id: 0,
    name: "",
    floorAreaId: 0,
    row: 0,
    position: 0,
  });

  const op = useRef(null);

  let locationPostionId = 0;

  useEffect(() => {
    if (areaContainerRef.current !== null) {
      const _locationsQtd = props.floorArea.locationQtd;
      const _rows = props.floorArea.rows;
      const separatedRow = Math.floor(_locationsQtd / _rows);

      if (!isNaN(separatedRow) && _rows > 0) {
        setLocationsPerRow(Math.ceil(_locationsQtd / _rows));
        setHasSmallerRow(_locationsQtd % _rows !== 0);
        setSmallerRowSize(_locationsQtd % _rows);
      }
    }
  }, [areaContainerRef, props.floorArea]);

  return (
    <div className="floor-area-draw">
      <table className="area-container" ref={areaContainerRef}>
        {props.floorArea.rows > 0 &&
          new Array(props.floorArea.rows).fill(0).map((_, rowIndex) => {
            return (
              <tr className="row" key={rowIndex}>
                {loactionsPerRow > 0 &&
                  new Array(loactionsPerRow).fill(0).map((_, cellIndex) => {
                    locationPostionId++;

                    const location = props.locations.find(
                      (x) => x.row === rowIndex && x.position === cellIndex
                    );
                    if (!location) return null;

                    return (
                      <td
                        className="square"
                        style={{ borderColor: props.floorArea.color }}
                        key={cellIndex}
                      >
                        <span
                          className="location-name"
                          onClick={(e) => {
                            if (op.current) {
                              setSelectedLocation(location);
                              op.current.toggle(e);
                            }
                          }}
                        >
                          {location.name}
                        </span>
                      </td>
                    );
                  })}
              </tr>
            );
          })}
      </table>

      <OverlayPanel ref={op}>
        <InputText
          value={selectedLocation.name}
          onChange={(e) =>
            setSelectedLocation({ ...selectedLocation, name: e.target.value })
          }
        ></InputText>
        <Button
          onClick={(e) => {
            if (props.onLocationNameChange) {
              op.current.toggle(e);
              props.onLocationNameChange(selectedLocation);
            }
          }}
        >
          <LanguageProvider id="gen.save" />
        </Button>
      </OverlayPanel>
    </div>
  );
}
