import { Chart } from "primereact/chart";
import settings from "~/services/settings.json";
import React, { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { defaultChartOptions } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import fakeData from "../../fake-data/stage-control/order-percentage-chart.json";
import { ThemeContext } from "~/app";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";

export default function OrderPercentageChart(configs) {
  const { currentTheme } = useContext(ThemeContext);
  const dcpAxiosService = useDcpAxiosService();
  const useMockData = useContext(UseMockDataContext);
  const [data, setData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    if (useMockData) {
      setData(fakeData);
    } else {
      const getAllocatedOrders = async () => {
        try {
          const { data, status } = await dcpAxiosService.get(
            settings.Urls.Rest.DashboardItem + "/list-allocated-orders-count",
            "StageControl"
          );
          if (status === 200) {
            const counts = data.data.map(item => item.allocatedOrdersTotalCount);

            setData({
              labels: [
                "OK",
                "Attention",
                "Critical"
              ],
              datasets: [
                {
                  data: counts,
                  backgroundColor: [
                    "rgba(70,192,189,255)",
                    "rgba(253,180,92,255)",
                    "rgba(247,71,74,255)"
                  ]
                }
              ]
            });
          }
        } catch (error) {
          console.error(error);
        }
      };

      getAllocatedOrders();
    }
  }, [useMockData]);

  const options = {
    ...defaultChartOptions,
    cutout: "50%",
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          color: currentTheme.dashboardDataColor,
        },
      },
    },
  };

  return (
    <Chart
      type="doughnut"
      data={data}
      options={options}
      width="100%"
      height="100%"
    />
  );
}