import { createContext, useContext, useState } from "react";

import enUS from "~/language/en-us.json";
import esES from "~/language/es-es.json";
import frFR from "~/language/fr-fr.json";
import ptBR from "~/language/pt-br.json";
import { getFromStorage } from "~/services/storage/storage-access";

const LanguageContext = createContext();
const translations = {
  "pt-br": ptBR,
  "es-es": esES,
  "en-us": enUS,
  "fr-fr": frFR,
};

const LanguageProvider = ({ children }) => {
  const selectedLanguageByUserInLocalStorage =
    getFromStorage("currentLanguage");
  const [currentLanguage, setCurrentLanguage] = useState(
    selectedLanguageByUserInLocalStorage
      ? selectedLanguageByUserInLocalStorage
      : "pt-br"
  );

  const changeLanguage = (lang) => {
    setCurrentLanguage(lang);
  };
  return (
    <LanguageContext.Provider
      value={{ currentLanguage, changeLanguage, translations }}
    >
      {children}
    </LanguageContext.Provider>
  );
};

const useLanguageContext = () => {
  const context = useContext(LanguageContext);
  if (!context) {
    throw new Error(
      "useLanguageContext have to be used within LanguageProvider"
    );
  }
  return context;
};
export { LanguageProvider, useLanguageContext };
