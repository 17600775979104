import React from "react";
import ptBR from "~/language/pt-br.json";
import enUS from "~/language/en-us.json";
import esES from "~/language/es-es.json";
import frFR from "~/language/fr-fr.json";
import { useLanguageContext } from "~/context/LanguageContext";
import * as GridItems from "../grid-item/items";

export interface DashboardMenuItem {
  type: string;
  label: any;
  icon: string;
  component: React.FC;
  description: any;
}

export interface DashboardMenuItemCategory {
  category: string;
  productCode: string;
  icon: string;
  label: any;
  items: DashboardMenuItem[];
}

const TranslatedLabel = ({ id }: { id: string }) => {
  const { currentLanguage } = useLanguageContext();

  const getTranslation = (id: string) => {
    switch (currentLanguage) {
      case "pt-br":
        return ptBR[id] || id;
      case "es-es":
        return esES[id] || id;
      case "en-us":
        return enUS[id] || id;
      case "fr-fr":
        return frFR[id] || id;
      default:
        return ptBR[id] || id;
    }
  };

  return <>{getTranslation(id)}</>;
};

export const GetMenuItems = (reports = []): DashboardMenuItemCategory[] => {
  return [
    {
      category: "layout",
      productCode: "platform",
      icon: "layout-grid-01",
      label: <TranslatedLabel id={"dashboard.category.layout"} />,
      items: [
        {
          type: "title",
          label: <TranslatedLabel id={"dashboard.item.type.title"} />,
          icon: "type-01",
          component: GridItems.Title,
          description: (
            <TranslatedLabel id={"dashboard.item.type.title.description"} />
          ),
        },
        {
          type: "text",
          label: <TranslatedLabel id={"dashboard.item.type.text"} />,
          icon: "align-left",
          component: GridItems.Text,
          description: (
            <TranslatedLabel id={"dashboard.item.type.text.description"} />
          ),
        },
      ],
    },
    // {
    //   category: "platform",
    //   productCode: "platform",
    //   icon: "bar-line-chart",
    //   label: LanguageProviderWithoutContext({ id: "gen.platform" }),
    //   items: [
    //     {
    //       type: "grafana",
    //       label: LanguageProviderWithoutContext({
    //         id: "dashboard.item.type.external-url",
    //       }),
    //       icon: "menu-01",
    //       component: GridItems.Grafana,
    //       description: LanguageProviderWithoutContext({
    //         id: "dashboard.item.type.external-url.description",
    //       }),
    //     },
    //   ],
    // },
    {
      category: "inventory",
      productCode: "drone-inventory",
      icon: "bar-line-chart",
      label: <TranslatedLabel id={"gen.inventory"} />,
      items: [
        {
          type: "planned-locations",
          label: (
            <TranslatedLabel id={"dashboard.item.type.planned-locations"} />
          ),
          icon: "menu-01",
          component: GridItems.InventoryLocationHistory,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "status-location-day",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.inventory.location.history"}
            />
          ),
          icon: "menu-01",
          component: GridItems.InventoryLocationHistory,
          description: (
            <TranslatedLabel
              id={"dashboard.item.type.inventory.location.history.description"}
            />
          ),
        },
        {
          type: "inventory-locations-counter",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.inventory.count.locations"}
            />
          ),
          icon: "type-01",
          component: GridItems.InventoryLocationsCounter,
          description: (
            <TranslatedLabel
              id={"dashboard.item.type.inventory.locations.counter.description"}
            />
          ),
        },
        {
          type: "open-inventory-accuracy",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.open-inventory-accuracy"}
            />
          ),
          icon: "type-01",
          component: GridItems.OpenInventoryAccuracy,
          description: (
            <TranslatedLabel
              id={"dashboard.item.type.open-inventory-accuracy.description"}
            />
          ),
        },
        {
          type: "counts-by-day",
          label: <TranslatedLabel id={"dashboard.item.type.counts-by-day"} />,
          icon: "type-01",
          component: GridItems.InventoryCountsByDay,
          description: (
            <TranslatedLabel
              id={"dashboard.item.type.counts-by-day.description"}
            />
          ),
        },
      ],
    },
    {
      category: "mes",
      productCode: "mes",
      icon: "bar-line-chart",
      label: <TranslatedLabel id={"dashboard.category.mes"} />,
      items: [
        {
          type: "libracom-producao-stack-chart",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.libracom-producao-stack-chart"}
            />
          ),
          icon: "menu-01",
          component: GridItems.LibracomParadaProducaoStackChart,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "libracom-producao-bar-chart",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.libracom-producao-bar-chart"}
            />
          ),
          icon: "bar-chart-10",
          component: GridItems.LibracomProducaoBarChart,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "libracom-ensaque-line-chart",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.libracom-ensaque-line-chart"}
            />
          ),
          icon: "bar-line-chart",
          component: GridItems.LibracomEnsaqueLineChart,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "performance-percentage",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.performance-percentage"}
            />
          ),
          icon: "speedometer-03",
          component: GridItems.PerformancePercentage,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "availability-horizontal-bar",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.availability-horizontal-bar"}
            />
          ),
          icon: "time-quarter-to",
          component: GridItems.AvailabilityHorizontalBar,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "oee-history",
          label: <TranslatedLabel id={"dashboard.item.type.oee-history"} />,
          icon: "line-chart-up-02",
          component: GridItems.OEEHistory,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "productivity-horizontal-bar",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.productivity-horizontal-bar"}
            />
          ),
          icon: "line-chart-up-02",
          component: GridItems.ProductivityHorizontalBar,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "productivity-hour-bar",
          label: (
            <TranslatedLabel id={"dashboard.item.type.productivity-hour-bar"} />
          ),
          icon: "line-chart-up-02",
          component: GridItems.ProductivityHourBar,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "production-count",
          label: (
            <TranslatedLabel id={"dashboard.item.type.production-count"} />
          ),
          icon: "number",
          component: GridItems.ProductionCount,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
    {
      category: "wms",
      productCode: "wms",
      icon: "bar-line-chart",
      label: <TranslatedLabel id={"dashboard.category.wms"} />,
      items: [
        {
          type: "occupation-level",
          label: (
            <TranslatedLabel id={"dashboard.item.type.occupation-level"} />
          ),
          icon: "box",
          component: GridItems.OccupationLevel,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "item-picking-hour",
          label: (
            <TranslatedLabel id={"dashboard.item.type.item-picking-hour"} />
          ),
          icon: "box",
          component: GridItems.ItemPickingHour,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "process-volume",
          label: <TranslatedLabel id={"dashboard.item.type.process-volume"} />,
          icon: "box",
          component: GridItems.ProcessVolume,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "wms-generic-counter",
          label: <TranslatedLabel id={"dashboard.item.type.generic-counter"} />,
          icon: "box",
          component: GridItems.GenericCounter,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "user-shipped-items",
          label: (
            <TranslatedLabel id={"dashboard.item.type.user-shipped-items"} />
          ),
          icon: "box",
          component: GridItems.UserShippedItems,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "inbound-heat-map",
          label: (
            <TranslatedLabel id={"dashboard.item.type.inbound-heat-map"} />
          ),
          icon: "box",
          component: GridItems.InboundHeatMap,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "average-order-time-cycle",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.average-order-time-cycle"}
            />
          ),
          icon: "box",
          component: GridItems.AverageOrderTimeCycle,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
    {
      category: "sequence",
      productCode: "sequence",
      icon: "bar-line-chart",
      label: <TranslatedLabel id={"product.sequence"} />,
      items: [
        {
          type: "dispatch-volume-by-part-number",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.dispatch-volume-by-part-number"}
            />
          ),
          icon: "box",
          component: GridItems.DispatchVolumeByPartNumber,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "dispatch-demand",
          label: <TranslatedLabel id={"dashboard.item.type.dispatch-demand"} />,
          icon: "box",
          component: GridItems.DispatchDemand,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "total-dispatch-volume",
          label: (
            <TranslatedLabel id={"dashboard.item.type.total-dispatch-volume"} />
          ),
          icon: "box",
          component: GridItems.TotalDispatchVolume,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "dispatch-by-user",
          label: (
            <TranslatedLabel id={"dashboard.item.type.dispatch-by-user"} />
          ),
          icon: "box",
          component: GridItems.DispatchByUser,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "errors-by-user",
          label: <TranslatedLabel id={"dashboard.item.type.errors-by-user"} />,
          icon: "box",
          component: GridItems.ErrorsByUser,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "sequence-generic-counter",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.sequence-generic-counter"}
            />
          ),
          icon: "box",
          component: GridItems.SequenceGenericCounter,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
    {
      category: "warehouse",
      productCode: "warehouse",
      icon: "bar-line-chart",
      label: <TranslatedLabel id={"product.warehouse"} />,
      items: [
        {
          type: "most-consumed-items",
          label: (
            <TranslatedLabel id={"dashboard.item.type.most-consumed-items"} />
          ),
          icon: "box",
          component: GridItems.MostConsumedItems,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "finance-counter",
          label: <TranslatedLabel id={"dashboard.item.type.finance-counter"} />,
          icon: "box",
          component: GridItems.FinanceCounter,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "warehouse-generic-counter",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.warehouse-generic-counter"}
            />
          ),
          icon: "box",
          component: GridItems.WarehouseGenericCounter,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "input-output",
          label: <TranslatedLabel id={"dashboard.item.type.input-output"} />,
          icon: "box",
          component: GridItems.InputOutput,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
    {
      category: "Area Movement Control",
      productCode: "area-movement-control",
      icon: "bar-line-chart",
      label: <TranslatedLabel id={"product.area.movement.control"} />,
      items: [
        {
          type: "amc-generic-counter",
          label: (
            <TranslatedLabel id={"dashboard.item.type.amc-generic-counter"} />
          ),
          icon: "box",
          component: GridItems.AmcGenericCounter,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "tasks-by-user",
          label: <TranslatedLabel id={"dashboard.item.type.tasks-by-user"} />,
          icon: "box",
          component: GridItems.TasksByUser,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "movement-by-address",
          label: (
            <TranslatedLabel id={"dashboard.item.type.movement-by-address"} />
          ),
          icon: "box",
          component: GridItems.MovmentByAddress,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "request-volume",
          label: <TranslatedLabel id={"dashboard.item.type.request-volume"} />,
          icon: "box",
          component: GridItems.RequestVolume,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "user-average-task-time",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.user-average-task-time"}
            />
          ),
          icon: "box",
          component: GridItems.UserAverageTaskTime,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
    {
      category: "documents-print",
      productCode: "documents-print",
      icon: "bar-line-chart",
      label: <TranslatedLabel id={"product.documents.print"} />,
      items: [
        {
          type: "documents-print-generic-counter",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.production-count-print-documents"}
            />
          ),
          icon: "box",
          component: GridItems.GenericCounterDocumentsPrint,
          description: (
            <TranslatedLabel
              id={
                "dashboard.item.type.production-count-print-documents.description"
              }
            />
          ),
        },
        {
          type: "documents-print-line-chart",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.documents-print-line-chart"}
            />
          ),
          icon: "bar-line-chart",
          component: GridItems.DocumentsPrintLineChart,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "documents-print-operator-productivity",
          label: (
            <TranslatedLabel
              id={
                "dashboard.item.type.documents-print-operator-productivity-chart"
              }
            />
          ),
          icon: "bar-line-chart",
          component: GridItems.PrintDocumentOperatorProductivityChart,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
    {
      category: "Ceva Core Picking",
      productCode: "wms-core-ceva",
      icon: "bar-line-chart",
      label: "Ceva Core Picking",
      items: [
        {
          type: "documents-print-operator-productivity",
          label: (
            <TranslatedLabel
              id={
                "dashboard.item.type.documents-print-operator-productivity-chart"
              }
            />
          ),
          icon: "bar-line-chart",
          component: GridItems.WmsCoreCevaOperatorProductivityChart,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
    {
      category: "Stage Control",
      productCode: "stage-control",
      icon: "bar-line-chart",
      label: "Stage Control",
      items: [
        {
          type: "stage-control-card-chart",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.stage-control-card-orders-chart"}
            />
          ),
          icon: "bar-line-chart",
          component: GridItems.StageControlCardOrders,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "stage-control-list-chart",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.stage-control-list-orders-chart"}
            />
          ),
          icon: "bar-line-chart",
          component: GridItems.StageControlListOrders,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
        {
          type: "stage-control-percentage-chart",
          label: (
            <TranslatedLabel
              id={"dashboard.item.type.stage-control-orders-percentage-chart"}
            />
          ),
          icon: "bar-line-chart",
          component: GridItems.StageControlOrdersPercentage,
          description:
            "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
        },
      ],
    },
    // {
    //   category: "reports",
    //   productCode: "platform",
    //   icon: "layout-alt-01",
    //   label: LanguageProviderWithoutContext({ id: "dashboard.category.reports" }),
    //   items: reports.map((report) => {
    //     return {
    //       type: "table",
    //       label: report.title,
    //       icon: "layout-alt-01",
    //       component: GridItems.Table,
    //       description:
    //         "Paliga yrkessåpa. Kaskap trevis. Kans viagra, luvis till prekronde om lol. Intranyv denyngen krympflation ben.",
    //     };
    //   }),
    // },
  ];
};
