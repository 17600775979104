import { Formik, useFormik } from "formik";
import { Button } from "~/shared/components/dcp-button";
import { InputText } from "primereact/inputtext";
import { classNames } from "primereact/utils";
import { useCallback, useContext, useEffect, useState, useRef } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useParams } from "react-router-dom";
import { ThemeContext, UserContext } from "~/app";
import { useToastContext } from "~/context/ToastContext";
import { InputContainer } from "~/shared/components/dcp-form";
import { Preview } from "../components/new-entry-edit";
import { FormField } from "./components/form-field";
import { useNavigate } from "react-router-dom";
import { useMenuItemsContext } from "~/context/MenuItemsContext";
import settings from "~/services/settings.json";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import Icon from "~/shared/components/icons";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import LayerFormBuildImg from "~/theme/media/backgrounds/LayerFormBuild.svg";
import LanguageProvider from "~shared/components/language-provider";
import "../scss/database-new.scss";
import {
  checkDatabaseName,
  createDatabase,
  createDatabseItemsByList,
  getDatabase,
  getDatabaseFields,
} from "~/services/api/";
import { TemplatePatterDialog } from "./components/template-pattern-dialog";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { useLanguageContext } from "~/context/LanguageContext";

export default function DatabaseNew() {
  const dcpAxiosService = useDcpAxiosService();
  const navigate = useNavigate();
  const { projectId } = useParams();
  const { currentLanguage } = useLanguageContext();
  const { showToast } = useToastContext();
  const { currentTheme } = useContext(ThemeContext);
  const [submitting, setSubmitting] = useState(false);
  const [loading, setLoading] = useState(true);
  const { idDatabase } = useParams();
  const { loadMenuItems } = useMenuItemsContext();
  const [searchValue, setSearchValue] = useState("");
  const [fieldTypes, setFieldTypes] = useState([]);
  const [formFields, setFormFields] = useState([]);
  const [formIsEmpty, setFormIsEmpty] = useState(true);
  const [formPreviewVisible, setFormPreviewVisible] = useState(false);

  // Refs
  const firstRenderComplete = useRef(false);

  // Dialaog controll
  const [clearConfirmVisible, setClearConfirmVisible] = useState(false);
  const [templateModalVisible, setTemplateModalVisible] = useState(false);

  const databaseFormik = useFormik({
    initialValues: {
      name: "",
      id: 0,
      modified: false,
      displayPattern: "",
    },
    validate: (data) => {
      let errors = {};
      if (!data.name) {
        errors.name = (
          <LanguageProvider id={"database.create.title.validation.error"} />
        );
      }
      return errors;
    },
    onSubmit: onDatabaseSave,
  });
  const loadDatabase = async (databaseId) => {
    try {
      const database = await getDatabase(databaseId);

      databaseFormik.setValues({
        name: database.name,
        id: database.id,
        modified: false,
        displayPattern: database.displayPattern,
      });
    } catch (error) {
      console.error(error);
    }
  };
  const loadFormFields = async (databaseId) => {
    try {
      const databaseFields = await getDatabaseFields(databaseId);
      const _formFields = databaseFields.map((field) => {
        return {
          id: field.id,
          required: field.required,
          hasTextHelp: field.hasTextHelp,
          textHelp: field.textHelp,
          hasDescription: field.hasDescription,
          description: field.description,
          hasInputMask: field.hasInputMask,
          inputMaskName: field.inputMaskName,
          viewName: field.viewName,
          keyName: field.keyName,
          idTypeValue: field.idTypeValue,
          typeDatabaseId: field.typeDatabaseId,
          type: field.typeValue.name,
          idDatabase: field.idDatabase,
          options: field.options,
          iconName: field.typeValue.icon,
          sequence: field.sequence,
          isReadOnly: field.typeValue.isReadOnly,
        };
      });
      setFormFields(_formFields);
      setFormIsEmpty(_formFields.length >= 0 ? false : true);
    } catch (error) {
      console.error(error);
    }
  };
  async function onDatabaseSave() {
    let errorDescription = "";

    const existentDatabaseData = await checkDatabaseName(
      0,
      databaseFormik.values.name
    );

    if (existentDatabaseData && databaseFormik.values.id === 0) {
      errorDescription =
        LanguageProviderWithoutContext({
          id: "database.name.already.exists.one",
          currentLanguage,
        }) +
        existentDatabaseData.name +
        LanguageProviderWithoutContext({
          id: "database.name.already.exists.two",
          currentLanguage,
        });
      showToast({
        severity: "error",
        message: errorDescription,
      });
    } else {
      setSubmitting(true);

      try {
        let _idDatabase = idDatabase;

        if (!idDatabase) {
          const newDatabasePayload = {
            id: 0,
            name: databaseFormik.values.name,
          };

          const newDatabase = await createDatabase(newDatabasePayload);

          if (newDatabase) {
            _idDatabase = newDatabase.id;
          } else {
            errorDescription = LanguageProviderWithoutContext({
              id: "database.creation.generic.error",
              currentLanguage,
            });
          }
        }

        if (databaseFormik.values.id > 0 && databaseFormik.values.modified)
          await createDatabase(databaseFormik.values);

        const payload = formFields.map((field) => {
          return {
            field: { ...field, idDatabase: _idDatabase },
            selectOptions: [...field.options],
          };
        });

        const itemsCreated = await createDatabseItemsByList(payload);
        if (itemsCreated) {
          showToast({
            severity: "success",
            message: LanguageProviderWithoutContext({
              id: "database.saved",
              currentLanguage,
            }),
          });
        }

        loadMenuItems();
      } catch (error) {
        showToast({
          severity: "error",
          message: errorDescription,
        });
      }
    }
    setSubmitting(false);
  }
  const loadFieldTypes = useCallback(async () => {
    try {
      const { data } = await dcpAxiosService.get(
        `${settings.Urls.Rest.TypeValue}/list`,
        "Platform"
      );
      if (data.status) {
        setFieldTypes(
          data.data.map((fieldType) => {
            return {
              ...fieldType,
              searchValue: LanguageProviderWithoutContext({
                id: `database.field.type.${fieldType.name}`,
                currentLanguage,
              }),
            };
          })
        );
      } else {
        showToast({
          severity: "error",
          message: LanguageProviderWithoutContext({
            id: data.message,
            currentLanguage,
          }),
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [showToast]);
  const updateFormField = (updatedField) => {
    try {
      const updatedFields = formFields.map((formField) => {
        if (updatedField.sequence === formField.sequence) return updatedField;
        return formField;
      });

      setFormFields(updatedFields);
    } catch (error) {
      console.error(error);
    }
  };
  const deleteFormField = (field) => {
    try {
      const deleteIndex = formFields.indexOf(field);
      let fields = [...formFields];

      if (field.id === 0) {
        fields.splice(deleteIndex, 1);
      } else {
        fields[deleteIndex].id = field.id * -1;
      }

      fields = updateSequece(fields);
      setFormFields(fields);
    } catch (error) {
      console.error(error);
    }
  };
  const duplicateFormField = (field) => {
    try {
      const newField = { ...field, viewName: field.viewName + " Cópia" };
      let fields = [...formFields];
      fields.splice(fields.indexOf(field) + 1, 0, newField);

      fields = updateSequece(fields);
      setFormFields(fields);
    } catch (error) {
      console.error(error);
    }
  };
  const isCreateDatabaseTitleFormValid = (name) => {
    return !!databaseFormik.errors[name];
  };
  const handleOnDragEnd = (result) => {
    const { source, destination } = result;
    if (!destination || !source) return;

    /** New Field */
    if (
      source.droppableId === "fields" &&
      destination.droppableId === "generatedForm"
    ) {
      const fieldType = fieldTypes.find(
        (field) => field.name === result.draggableId
      );

      let newFormField = {
        id: 0,
        required: false,
        hasTextHelp: false,
        textHelp: "",
        hasDescription: false,
        description: "",
        type: fieldType.name,
        viewName: fieldType.searchValue,
        idTypeValue: fieldType.id,
        idDatabase: undefined,
        options: [],
        iconName: fieldType.icon,
        sequence: destination.index,
        originalLabel: fieldType.searchValue,
        isReadOnly: fieldType.isReadOnly,
      };

      const newFormFields = [...formFields];
      newFormFields.splice(destination.index, 0, newFormField);

      // Update fields sequence
      newFormFields.forEach((field, index) => {
        field.sequence = index;
      });

      setFormFields(newFormFields);
    } else if (
      source.droppableId === "generatedForm" &&
      destination.droppableId === "generatedForm"
    ) {
      let _fields = Array.from(formFields);
      const [removedField] = _fields.splice(source.index, 1);
      _fields.splice(destination.index, 0, removedField);

      // Update fields sequence
      _fields = updateSequece(_fields);
      setFormFields(_fields);
    }
  };
  const updateSequece = (fields) => {
    try {
      let updatedFields = [...fields];
      let sequence = 0;

      updatedFields.forEach((field) => {
        if (field.id >= 0) {
          field.sequence = sequence;
          sequence++;
        }
      });

      return updatedFields;
    } catch (error) {
      console.error(error);
    }
  };
  const handleSearchValueChange = (e) => {
    setSearchValue(e.target.value);
  };

  useEffect(() => {
    async function load() {
      setLoading(true);
      await loadFieldTypes();
      await loadMenuItems();
      setLoading(false);
    }
    if (!firstRenderComplete.current) {
      load();
      firstRenderComplete.current = true;
    }
  }, [loadFieldTypes, loadMenuItems]);

  useEffect(() => {
    async function load() {
      setLoading(true);
      await loadDatabase(idDatabase);
      await loadFormFields(idDatabase);
      setLoading(false);
    }
    load();
  }, [idDatabase]); // loadDatabase dependency will cause infinite loop

  useEffect(() => {
    setFormIsEmpty(formFields.length > 0 ? false : true);
  }, [formFields]);

  if (loading)
    return <LoadingIndicator size="large" color={currentTheme.primary} />;

  return (
    <>
      <div className="database-content">
        <div className="database-body">
          <div className="database-new">
            <form
              onSubmit={databaseFormik.handleSubmit}
              className="dcp-form"
              style={{ paddingBottom: 0, gap: 0 }}
            >
              <DragDropContext onDragEnd={handleOnDragEnd}>
                <div className="database-content">
                  <div className="form-body">
                    <div className="database-header">
                      <div className="header-database-manage-wrapper">
                        <div
                          className="back-button"
                          onClick={() => {
                            navigate("/");
                          }}
                        >
                          <span>
                            <Icon
                              icon={"arrow-narrow-left"}
                              size={20}
                              color={currentTheme.sideMenuLabelColor}
                            />
                          </span>
                        </div>
                        <div
                          className="visualize-database-button"
                          onClick={() => {
                            projectId !== undefined && projectId.length > 0
                              ? navigate(
                                  `/${projectId}/database/list/${idDatabase}`
                                )
                              : navigate(`/database/list/${idDatabase}`);
                          }}
                        >
                          <span>
                            <Icon
                              icon={"layout-alt-01"}
                              size={20}
                              color={currentTheme.sideMenuLabelColor}
                            />
                          </span>
                          <span className="preview-label">
                            <LanguageProvider id={"database.return"} />
                          </span>
                        </div>
                        <div
                          className="preview-button"
                          onClick={() => {
                            setFormPreviewVisible(!formPreviewVisible);
                          }}
                        >
                          <span>
                            <Icon
                              icon={formPreviewVisible ? "eye-off" : "eye"}
                              size={20}
                              color={currentTheme.sideMenuLabelColor}
                            />
                          </span>
                          <span className="preview-label">
                            <LanguageProvider
                              id={
                                formPreviewVisible
                                  ? "gen.edit.button"
                                  : "database.preview"
                              }
                            />
                          </span>
                        </div>
                      </div>
                      <div className="options-buttons">
                        <Button
                          appearance="secondary"
                          type="button"
                          onClick={() => setTemplateModalVisible(true)}
                        >
                          <Icon
                            icon="settings-01"
                            size={20}
                            color={currentTheme.textPrimary}
                          />
                        </Button>
                        <Button
                          appearance="secondary"
                          disabled={formIsEmpty}
                          type="button"
                          onClick={() => setClearConfirmVisible(true)}
                          className="clear-button"
                        >
                          <LanguageProvider id="database.clear" />
                        </Button>
                        <Button
                          type="submit"
                          loading={submitting}
                          disabled={formIsEmpty}
                        >
                          <LanguageProvider id="gen.save.button" />
                        </Button>
                      </div>
                    </div>
                    <div className="form-wrapper">
                      {!formPreviewVisible && (
                        <>
                          <div className="form-header">
                            <div className="form-row">
                              <InputContainer>
                                <InputText
                                  placeholder="Titulo formulário"
                                  value={databaseFormik.values.name}
                                  onChange={(e) => {
                                    databaseFormik.setFieldValue(
                                      "name",
                                      e.target.value
                                    );

                                    databaseFormik.setFieldValue(
                                      "modified",
                                      true
                                    );
                                  }}
                                  className={classNames({
                                    "p-invalid":
                                      isCreateDatabaseTitleFormValid("name"),
                                    "p-inputtext-sm form-title": true,
                                  })}
                                />
                              </InputContainer>
                            </div>
                          </div>
                          <div className="form-model-body">
                            <div className="form-generated-wrapper">
                              <Droppable droppableId="generatedForm">
                                {(provided) => (
                                  <div
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                  >
                                    {formIsEmpty && (
                                      <div className="form-demo-wrapper">
                                        <div className="form-demo">
                                          <img src={LayerFormBuildImg} alt="" />
                                          <div className="form-example-body">
                                            <span>
                                              <LanguageProvider
                                                id={
                                                  "database.form.default.image"
                                                }
                                              />
                                            </span>
                                          </div>
                                        </div>
                                      </div>
                                    )}
                                    {formFields.map((field, index) => {
                                      if (field.id < 0) return null;
                                      return (
                                        <FormField
                                          key={`form-field-${index}`}
                                          index={index}
                                          field={field}
                                          fieldTypes={fieldTypes}
                                          onEdit={updateFormField}
                                          onDelete={deleteFormField}
                                          onDuplicate={duplicateFormField}
                                        />
                                      );
                                    })}
                                    {provided.placeholder}
                                  </div>
                                )}
                              </Droppable>
                            </div>
                          </div>
                        </>
                      )}
                      {formPreviewVisible && (
                        <Preview
                          fields={formFields}
                          name={databaseFormik.values.name}
                        />
                      )}
                    </div>
                  </div>

                  <div className="field-menu">
                    <span className="field-types-title">
                      <LanguageProvider id={"database.form.field.types"} />
                    </span>
                    <span className="field-types-description">
                      <LanguageProvider
                        id={"database.form.field.types.description"}
                      />
                    </span>
                    <span className="search-input p-input-icon-left">
                      <div className="icon-search">
                        <Icon icon={"search-md"} color="#98A2B3" size={20} />
                      </div>
                      <InputText
                        className="p-inputtext-sm"
                        value={searchValue}
                        onChange={handleSearchValueChange}
                        placeholder={LanguageProviderWithoutContext({
                          id: "search.field.placeholder",
                          currentLanguage,
                        })}
                      />
                    </span>
                    <Droppable droppableId="fields">
                      {(provided, snapshot) => {
                        return (
                          <ul
                            className="form-fields"
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            {!loading ? (
                              fieldTypes &&
                              fieldTypes
                                .filter((x) =>
                                  x.searchValue
                                    .toLowerCase()
                                    .includes(searchValue.toLowerCase())
                                )
                                .map((fieldType, index) => {
                                  return (
                                    <Draggable
                                      key={fieldType.id}
                                      draggableId={fieldType.name}
                                      index={index}
                                    >
                                      {(provided, snapshot) => {
                                        if (snapshot.isDragging) {
                                        }
                                        return (
                                          <li
                                            {...provided.draggableProps}
                                            {...provided.dragHandleProps}
                                            ref={provided.innerRef}
                                            key={fieldType.id}
                                            className="field-opt"
                                            style={{
                                              ...provided.draggableProps.style,
                                              border:
                                                snapshot.isDragging &&
                                                "1px solid var(--blue-gray-400, #717bbc)",
                                              boxShadow:
                                                snapshot.isDragging &&
                                                "0 4px 8px rgba(0, 0, 0, 0.2)",
                                              background:
                                                snapshot.isDragging &&
                                                "#d5d9eb",
                                            }}
                                          >
                                            <Icon
                                              icon={fieldType.icon}
                                              color={"#717BBC"}
                                              className={"icon-field"}
                                            />
                                            <LanguageProvider
                                              id={`database.field.type.${fieldType.name}`}
                                            />
                                          </li>
                                        );
                                      }}
                                    </Draggable>
                                  );
                                })
                            ) : (
                              <LoadingIndicator
                                size="small"
                                color={currentTheme.primary}
                              />
                            )}
                            {provided.placeholder}
                          </ul>
                        );
                      }}
                    </Droppable>
                  </div>
                </div>
              </DragDropContext>
            </form>
          </div>
        </div>
      </div>

      {/* Clear form modal */}
      <ModalConfirmation
        isOpen={clearConfirmVisible}
        modalTitle={<LanguageProvider id="database.clear" />}
        bodyMessage={<LanguageProvider id="database.clear.confirmation" />}
        isDelete={true}
        onCancel={() => setClearConfirmVisible(false)}
        onConfirm={() => {
          setFormFields([]);
          setClearConfirmVisible(false);
          setFormIsEmpty(true);
        }}
      />

      <TemplatePatterDialog
        visible={templateModalVisible}
        onHide={() => setTemplateModalVisible(false)}
        templatePattern={databaseFormik.values.displayPattern}
        onSave={(value) => {
          if (!databaseFormik.values.modified)
            databaseFormik.setFieldValue("modified", true);

          databaseFormik.setFieldValue("displayPattern", value);
          setTemplateModalVisible(false);
        }}
        suggestions={formFields
          .filter((x) => x.id > 0)
          .map((field) => {
            return {
              viewName: field.viewName,
              keyName: field.keyName,
              fieldId: field.id,
            };
          })}
      />
    </>
  );
}
