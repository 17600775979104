function writeStorage(key, value) {
  localStorage.setItem(key, JSON.stringify(value));
}

function writeStorageInObject(key, property, value) {
  try {
    const valueString = localStorage.getItem(key);
    if (!valueString) {
      console.error("Key not found in local storage");
      return false;
    }
    const obj = JSON.parse(valueString);
    if (property) {
      const keys = property.split(".");
      let current = obj;

      keys.forEach((key, index) => {
        if (index === keys.length - 1) {
          current[key] = value;
        } else {
          current = current[key];
        }
      });
    }
    localStorage.setItem(key, JSON.stringify(obj));
  } catch (error) {
    console.error(error);
  }
}

function removeStorage(key) {
  localStorage.removeItem(key);
}

function getFromStorage(key) {
  try {
    const valueString = localStorage.getItem(key);
    const value = JSON.parse(valueString);
    return value;
  } catch {
    return null;
  }
}

function propertyFrommStorage(key, property) {
  try {
    const valueString = localStorage.getItem(key);
    const value = JSON.parse(valueString);
    if (property && !property.includes(".")) return value[property];
    if (property) {
      return property.split(".").reduce((acc, prop) => acc?.[prop], value);
    }

    return value;
  } catch {
    return "";
  }
}

export {
  writeStorage,
  writeStorageInObject,
  removeStorage,
  getFromStorage,
  propertyFrommStorage,
};
