import "../scss/setup-print-input-fields-dialog.scss";

import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import { Dialog } from "primereact/dialog";
import { InputSwitch, InputSwitchChangeEvent } from "primereact/inputswitch";
import React, { useEffect, useState } from "react";
import { useToastContext } from "~/context/ToastContext";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";
import { Button } from "~/shared/components/dcp-button";
import LanguageProvider from "~/shared/components/language-provider";
import { useFormik } from "formik";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { useLanguageContext } from "~/context/LanguageContext";
import { FieldsPrint } from "~/shared/interfaces/document-print.ts";

export interface SetupPrintInputFieldsDialogProps {
  visible: boolean;
  onHide: () => void;
  printFieldsSettings: FieldsPrint[];
}

const SetupPrintInputFieldsDialog = ({
  visible,
  onHide,
  printFieldsSettings,
}: SetupPrintInputFieldsDialogProps) => {
  const { currentLanguage } = useLanguageContext();
  const dcpAxiosService = useDcpAxiosService();
  const { showToast } = useToastContext();
  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    console.log(printFieldsSettings);
  }, [printFieldsSettings]);

  const formik = useFormik({
    initialValues: printFieldsSettings,
    enableReinitialize: true,
    onSubmit: async (values) => {
      setSubmitting(true);

      try {
        const { data, status } = await dcpAxiosService.post(
          settings.Urls.Rest.fieldSettings + "/update",
          values,
          "PrintDocument"
        );

        if (status === 200) {
          showToast({
            severity: "success",
            message: LanguageProviderWithoutContext({
              id: "dcp.printdocument.input.print.data.saved",
              currentLanguage,
            }),
          });
          onHide();
        }
      } catch (error) {
        console.error(error);
      }

      setSubmitting(false);
    },
  });

  function onFieldToggle(e: InputSwitchChangeEvent, field: FieldsPrint) {
    const values = formik.values.map((v) => {
      if (v.code === field.code) return { ...v, enable: e.value };
      return v;
    });
    formik.setValues(values);
  }

  function onAutoEmptyToggle(e: InputSwitchChangeEvent, field: FieldsPrint) {
    const values = formik.values.map((v) => {
      if (v.code === field.code) return { ...v, autoEmpty: e.value };
      return v;
    });
    formik.setValues(values);
  }

  return (
    <Dialog
      className="setup-print-input-fields-dialog"
      visible={visible}
      onHide={onHide}
      header={() => {
        return (
          <div className="entry-dialog-header">
            <span className="entry-dialog-header-title">
              <LanguageProvider
                id={"dcp.platform.warehouse.entry.dialog.entry.configure"}
              />
            </span>
          </div>
        );
      }}
      footer={() => {
        return (
          <div className="entry-dialog-footer">
            <div className="actions">
              <Button
                onClick={onHide}
                className="p-button p-button-text p-button-plain"
              >
                <LanguageProvider id={"gen.cancel"} />
              </Button>
              <Button
                loading={submitting}
                type="submit"
                className="p-button"
                onClick={() => formik.handleSubmit()}
              >
                <LanguageProvider id={"gen.save.button"} />
              </Button>
            </div>
          </div>
        );
      }}
    >
      {printFieldsSettings && Array.isArray(printFieldsSettings) && (
        <div className="entry-dialog-content">
          <Form className="entry-dialog-content">
            {printFieldsSettings.map((field) => (
              <FormRow key={field.code}>
                <InputContainer label={field.name}>
                  <InputSwitch
                    name={field.name}
                    checked={
                      formik.values.find((f) => f.code === field.code)?.enable
                    }
                    onChange={(e) => onFieldToggle(e, field)}
                  />
                </InputContainer>
                <InputContainer
                  label={<LanguageProvider id={"auto.empty.msg"} />}
                >
                  <InputSwitch
                    name={field.name}
                    checked={
                      formik.values.find((f) => f.code === field.code)
                        ?.autoEmpty
                    }
                    onChange={(e) => onAutoEmptyToggle(e, field)}
                  />
                </InputContainer>
              </FormRow>
            ))}
          </Form>
        </div>
      )}
    </Dialog>
  );
};

export default SetupPrintInputFieldsDialog;
