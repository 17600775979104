import { useLanguageContext } from "~/context/LanguageContext";
import enUS from "../../language/en-us.json";
import esES from "../../language/es-es.json";
import ptBR from "../../language/pt-br.json";
import frFR from "../../language/fr-fr.json";

const LanguageProvider = ({ id }) => {
  const { currentLanguage } = useLanguageContext();

  if (!id) return "Language id required!";
  let label;
  switch (currentLanguage) {
    case "pt-br":
      label = ptBR[id];
      break;
    case "es-es":
      label = esES[id];
      break;
    case "en-us":
      label = enUS[id];
      break;
    case "fr-fr":
      label = frFR[id];
      break;
    default:
      label = ptBR[id];
      break;
  }
  return label ? label : id;
};

export default LanguageProvider;
