import "./scss/summary.scss";

import {
  InventoryStatus,
  InventoryUpdateStatusModel,
} from "~/shared/interfaces";
import React, { useContext, useEffect, useState } from "react";
import { UpdateInventoryStatus, getUsers } from "~/services/api";
import { formatDate, formatElapsedTime } from "~/shared/helpers/utils.ts";

import { Avatar } from "primereact/avatar";
import { Button } from "~/shared/components/dcp-button";
import { ReactComponent as ClockStart } from "~/theme/custom-icons/clock-start.svg";
import { ReactComponent as ClockStop } from "~/theme/custom-icons/clock-stop.svg";
import { ConfirmPopup } from "primereact/confirmpopup";
import { ReactComponent as Drone } from "~/theme/custom-icons/drone-inventory.svg";
import Icon from "~/shared/components/icons";
import { ImageUpload } from "~/pages/inventory/inventory/inventory-details/components/image-upload.tsx";
import { InventoryContext } from "~/pages/inventory/inventory/inventory-details/inventory-details.tsx";
import { InventoryStatusBadge } from "./components/inventory-status";
import LanguageProvider from "~/shared/components/language-provider";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import { ReactComponent as Manual } from "~/theme/custom-icons/manual-inventory.svg";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import { RadioButton } from "primereact/radiobutton";
import { ThemeContext } from "~/app";
import { useNavigate } from "react-router-dom";

interface SummaryProps {
  loading: boolean;
}

export function Summary(props: SummaryProps) {
  const { currentTheme } = useContext(ThemeContext);
  const [users, setUsers] = useState([]);

  const inventoryContext = useContext(InventoryContext);
  const navigate = useNavigate();

  const [imageUploadDialogVisible, setImageUploadDialogVisible] =
    useState<boolean>(false);

  async function onStatusChange(status: InventoryStatus) {
    try {
      const model: InventoryUpdateStatusModel = {
        inventoryId: inventoryContext.inventory.id,
        status: status,
      };

      const _inventory = await UpdateInventoryStatus(model);
      if (_inventory) inventoryContext.setInventory(_inventory);
    } catch (e) {
      console.error(e);
    }
  }

  useEffect(() => {
    async function initialize() {
      try {
        const users = await getUsers();
        setUsers(users.data);
      } catch (error) {
        console.error(error);
      }
    }
    initialize();
  }, []);

  return (
    <div className="inventory-details-header">
      <div className="header-container">
        <div className="header-action">
          <Icon
            icon={"arrow-narrow-left"}
            size={25}
            color={currentTheme.reportBackIconColor}
            onClick={() => navigate(-1)}
            className="back-icon"
          />
          <h1>
            <LanguageProvider id="inventory.details" />
          </h1>
        </div>
        <div className="header-buttons">
          {inventoryContext.inventory && (
            <>
              {inventoryContext?.inventory?.drones?.length > 0 && (
                <>
                  <Button
                    appearance={"terciary"}
                    onClick={() => setImageUploadDialogVisible(true)}
                    disabled={
                      inventoryContext.inventory.status ===
                        InventoryStatus.Canceled ||
                      inventoryContext.inventory.status ===
                        InventoryStatus.Finished
                    }
                  >
                    <Icon
                      icon={"upload-cloud-02"}
                      size={20}
                      color={"var(--gray-400"}
                    ></Icon>
                  </Button>
                </>
              )}

              <StatusControls
                status={inventoryContext.inventory.status}
                onClick={onStatusChange}
              ></StatusControls>
            </>
          )}
        </div>
      </div>
      <div className="content-container">
        {props.loading || !inventoryContext.inventory ? (
          <LoadingIndicator></LoadingIndicator>
        ) : (
          <>
            <div id="first-section">
              <div className="info-wrapper">
                <div className="field">
                  <p className="label">
                    <LanguageProvider id="gen.code" />
                  </p>
                  <p className="text">{inventoryContext.inventory.code}</p>
                </div>
                <div className="field">
                  <p className="label">
                    <LanguageProvider id="gen.name" />
                  </p>
                  <p className="text">{inventoryContext.inventory.name}</p>
                </div>
              </div>
              <div className="area-wrapper">
                <div className="label">
                  <LanguageProvider id="areas" />
                </div>
                <div className="areas">
                  {inventoryContext.inventory.areas &&
                    inventoryContext.inventory.areas.map((area, index) => {
                      return (
                        <p key={"area-" + index} className="area">
                          {area.name}
                        </p>
                      );
                    })}
                </div>
              </div>
              <div className="time-wrapper">
                <div className="row">
                  <div className="time">
                    <p className="label">
                      <LanguageProvider id="inventory.programmed.to.date" />
                    </p>
                    <div className="content">
                      <Icon icon="calendar" color="#667085" size={undefined} />
                      <p className="text">
                        {formatDate(inventoryContext.inventory.programmedTo)}
                      </p>
                    </div>
                  </div>
                  <div className="time">
                    <p className="label">
                      <LanguageProvider id="gen.duration" />
                    </p>
                    <div className="content">
                      <Icon icon="clock" color="#667085" size={undefined} />
                      <p className="text">
                        {inventoryContext.inventory.duration}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="time">
                    <p className="label">
                      <LanguageProvider id="gen.begin" />
                    </p>
                    <div className="content">
                      <ClockStart />
                      <p className="text">
                        {formatElapsedTime(
                          inventoryContext.inventory.startedAt
                        )}
                      </p>
                    </div>
                  </div>
                  <div className="time">
                    <p className="label">
                      <LanguageProvider id="gen.end" />
                    </p>
                    <div className="content">
                      <ClockStop />
                      <p className="text">
                        {formatElapsedTime(
                          inventoryContext.inventory.finishedAt
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="location-wrapper">
                <p className="label">
                  <LanguageProvider id="inventory.predicted.locations" />
                </p>
                <div className="bar-wrapper">
                  <div className="bar">
                    <div
                      className="section success"
                      style={{
                        width: `${
                          (340 /
                            inventoryContext.inventory.estimatedLocations) *
                          inventoryContext.inventory.locationsFinished
                        }px`,
                      }}
                    >
                      <span className="text">
                        {inventoryContext.inventory.locationsFinished > 0
                          ? inventoryContext.inventory.locationsFinished
                          : ""}
                      </span>
                    </div>
                    <div
                      className="section error"
                      style={{
                        width: `${
                          (340 /
                            inventoryContext.inventory.estimatedLocations) *
                          inventoryContext.inventory.locationsWithDivergency
                        }px`,
                      }}
                    >
                      <span className="text">
                        {inventoryContext.inventory.locationsWithDivergency > 0
                          ? inventoryContext.inventory.locationsWithDivergency
                          : ""}
                      </span>
                    </div>
                    <div className="section pending"></div>
                  </div>
                  <p className="text">{`${
                    inventoryContext.inventory.locationsWithDivergency +
                    inventoryContext.inventory.locationsFinished
                  }/${inventoryContext.inventory.estimatedLocations}`}</p>
                </div>
              </div>
            </div>
            <div id="second-section">
              <div className="type-container">
                <p className="label">
                  <LanguageProvider id="inventory.type" />
                </p>
                <div className="types">
                  {inventoryContext.inventory.drone && (
                    <div className="type">
                      <p className="text">
                        <LanguageProvider id="inventory.type.drone" />
                      </p>
                      <Drone />
                    </div>
                  )}
                  {inventoryContext.inventory.manual && (
                    <div className="type">
                      <p className="text">
                        <LanguageProvider id="inventory.type.manual" />
                      </p>
                      <Manual />
                    </div>
                  )}
                </div>
              </div>
              <div className="drones-container">
                <p className="label">
                  <LanguageProvider id="devices.drones" />
                </p>
                <div className="drones">
                  {inventoryContext.inventory.drones &&
                    inventoryContext.inventory.drones.map((drone, index) => {
                      return (
                        <p key={"drone-" + index} className="drone">
                          {drone.name}
                        </p>
                      );
                    })}
                </div>
              </div>
              <div className="read-type-container">
                <div className="label">
                  <LanguageProvider id="inventory.read.type" />
                </div>
                <div className="read-type">
                  <RadioButton checked={true} />
                  <span className="label">
                    {inventoryContext?.inventory.readType === 1
                      ? "SKU"
                      : "Serial"}
                  </span>
                </div>
              </div>
            </div>
            <div id="third-section">
              <div className="label">
                <LanguageProvider id="gen.responsibles" />
              </div>
              <div className="resposibles-wrapper">
                {users?.length > 0 &&
                  inventoryContext?.inventory?.responsibles &&
                  inventoryContext?.inventory?.responsibles.map(
                    (responsible, index) => {
                      const user = users.find(
                        (user) => user.userId === responsible
                      );
                      if (!user) return null;

                      return (
                        <div
                          key={"responsible" + index}
                          className="responsible"
                        >
                          <Avatar
                            label={user.name[0].toUpperCase()}
                            size="normal"
                            shape="circle"
                          />
                          <div className="info">
                            <p className="text">{user.name}</p>
                            <p className="label">{user.email}</p>
                          </div>
                        </div>
                      );
                    }
                  )}
              </div>
            </div>
            <div id="fourth-section">
              <p className="label">
                <LanguageProvider id="gen.status" />
              </p>
              <InventoryStatusBadge
                inventoryStatus={inventoryContext.inventory.status}
              />
              <Gauge percentage={inventoryContext.inventory.accuracy} />
            </div>
          </>
        )}
      </div>
      <ImageUpload
        visible={imageUploadDialogVisible}
        onClose={() => setImageUploadDialogVisible(false)}
      ></ImageUpload>
    </div>
  );
}

interface StatusControlsProps {
  status: InventoryStatus;
  onClick: (e: InventoryStatus) => void;
}

function StatusControls(props: StatusControlsProps) {
  const [selectedStatus, setSelectedStatus] = useState<InventoryStatus>();
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false);

  function confirm(status: InventoryStatus) {
    setSelectedStatus(status);
    setConfirmationDialogOpen(true);
  }

  function getConfirmationHeader(status: InventoryStatus) {
    switch (status) {
      case InventoryStatus.InProgress:
        return LanguageProvider({
          id: "inventory.status.change.confirmation.resume",
        });
      case InventoryStatus.Paused:
        return LanguageProvider({
          id: "inventory.status.change.confirmation.pause",
        });
      case InventoryStatus.Finished:
        return LanguageProvider({
          id: "inventory.status.change.confirmation.finish",
        });
      case InventoryStatus.Canceled:
        return LanguageProvider({
          id: "inventory.status.change.confirmation.cancel",
        });
    }
  }

  return (
    <div className="inventory-status-control">
      <ConfirmPopup />
      {props.status === InventoryStatus.Paused && (
        <Button onClick={(e) => confirm(InventoryStatus.InProgress)}>
          <LanguageProvider id={"inventory.status.control.resume"} />
          <Icon icon={"play"} size={20} color={"white"}></Icon>
        </Button>
      )}
      {props.status === InventoryStatus.InProgress && (
        <Button
          severity={"secondary"}
          onClick={(e) => confirm(InventoryStatus.Paused)}
        >
          <LanguageProvider id={"inventory.status.control.pause"} />
          <Icon
            icon={"pause-circle"}
            size={20}
            color={"var(--textSecondary)"}
          ></Icon>
        </Button>
      )}
      {(props.status === InventoryStatus.InProgress ||
        props.status === InventoryStatus.Paused) && (
        <Button
          severity={"danger"}
          onClick={(e) => confirm(InventoryStatus.Finished)}
        >
          <LanguageProvider id={"inventory.status.control.finish"} />
          <Icon icon={"stop"} size={20} color={"white"}></Icon>
        </Button>
      )}
      {props.status === InventoryStatus.WaitingStart && (
        <Button
          severity={"danger"}
          onClick={(e) => confirm(InventoryStatus.Canceled)}
        >
          <LanguageProvider id={"inventory.status.control.cancel"} />
          <Icon icon={"x-close"} size={20} color={"white"}></Icon>
        </Button>
      )}
      <ModalConfirmation
        isOpen={confirmationDialogOpen}
        modalTitle={getConfirmationHeader(selectedStatus)}
        bodyMessage={
          selectedStatus === InventoryStatus.Finished ||
          selectedStatus === InventoryStatus.Canceled ? (
            <LanguageProvider
              id={"inventory.status.change.confirmation.description.permanent"}
            />
          ) : (
            <LanguageProvider
              id={"inventory.status.change.confirmation.description"}
            />
          )
        }
        isDelete={
          selectedStatus === InventoryStatus.Finished ||
          selectedStatus === InventoryStatus.Canceled
        }
        confirmButtonText={<LanguageProvider id={"gen.message.yes"} />}
        onConfirm={() => {
          props.onClick(selectedStatus);
          setConfirmationDialogOpen(false);
        }}
        onCancel={() => setConfirmationDialogOpen(false)}
      ></ModalConfirmation>
    </div>
  );
}

// Accuracy Gauge Component
function Gauge({ percentage }) {
  const { currentTheme } = useContext(ThemeContext);
  const [path, setPath] = useState("");

  function percentageInRadians(percentage) {
    return percentage * (Math.PI / 100);
  }

  useEffect(() => {
    function setGaugeValue() {
      const gaugeRadius = 107;
      const startingY = 120;
      const startingX = 12;

      const zeroBasedY =
        gaugeRadius * Math.sin(percentageInRadians(percentage));
      const y = -zeroBasedY + startingY;
      const zeroBasedX =
        gaugeRadius * Math.cos(percentageInRadians(percentage));
      const x = -zeroBasedX + gaugeRadius + startingX;

      setPath(`M ${startingX} ${startingY}
             A ${gaugeRadius} ${gaugeRadius} 0 0 1 ${x} ${y}
             `);
    }

    setGaugeValue();
  }, [percentage]);

  return (
    <>
      <div className="svg-container">
        <div className="content">
          <p className="label">
            <LanguageProvider id="inventory.accuracy" />
          </p>
          <p className="value">{percentage}%</p>
        </div>
        <svg width="240" height="137" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M 12 120
          A 107 107 0 0 1 226 120
          "
            stroke={currentTheme.systemBackground}
            stroke-width="24"
            stroke-linecap="round"
          />
          <path
            d={path}
            stroke="#03CEB7"
            stroke-width="24"
            stroke-linecap="round"
            color="blue"
          />

          <g id="svg-graph-meter-value"></g>
        </svg>
      </div>
    </>
  );
}
