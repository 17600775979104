import "./scss/dashboard-graphs.scss";

import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";
import { Responsive, WidthProvider } from "react-grid-layout";
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";

import AiAnalytics from "~/shared/components/ai-analytics";
import { Button } from "~/shared/components/dcp-button";
import { Checkbox } from "primereact/checkbox";
import { ConfigSideMenu } from "./components/config-side-menu/config-side-menu";
import { DashboardAccessDialog } from "../../admin/dashboards/cards/dashboard-card";
import { Dialog } from "primereact/dialog";
import { ReactComponent as EmptyReportSVG } from "~/theme/custom-icons/empty-dashboard.svg";
import { GridItem } from "./components/grid-item/grid-item";
import Icon from "~/shared/components/icons";
import { ItemListDialog } from "./components/item-list-dialog/item-list-dialog";
import LanguageProvider from "~/shared/components/language-provider";
import LoadingIndicator from "~/shared/components/dcp-loading-indicator";
import { Menu } from "primereact/menu";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import { ProductContext } from "~/shared/main-layout";
import { ThemeContext, UserContext } from "~/app";
import { classNames } from "primereact/utils";
import { GetMenuItems } from "./components/item-list-dialog/menu-items";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import settings from "~/services/settings.json";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { useFormik } from "formik";
import { useMenuItemsContext } from "~/context/MenuItemsContext";
import { useToastContext } from "~/context/ToastContext";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import ModalSettingsDashboardTimers from "~/shared/components/modal-settings-dashboard-timers";
import { checkAIService } from "~/services/api";

export const UseMockDataContext = createContext(false);

export default function DashboardGraphs({
  previewMode = false,
  isMain = false,
  external = false,
  productId = 0,
}) {
  // Cusomt hooks
  const dcpAxiosService = useDcpAxiosService();
  const { currentLanguage } = useLanguageContext();
  const { userRoles } = useContext(UserContext);
  const { loadMenuItems } = useMenuItemsContext();
  const { showToast } = useToastContext();
  const { product } = useContext(ProductContext);
  const location = useLocation();
  const applicationClientId = propertyFrommStorage(
    "authentication",
    "clientId"
  );

  // Contexts
  const { currentTheme } = useContext(ThemeContext);

  // Data
  const { idDashboard } = useParams();
  const [dashboard, setDashboard] = useState({});
  const [dashboardIdForRefresh, setDashboardIdForRefresh] = useState(0);
  const [dashboardItems, setDashboardItems] = useState([]);
  const [selectedItem, setSelectedItem] = useState();
  const [roles, setRoles] = useState([]);
  const [itemTypes, setItemTypes] = useState([]);

  // Element visibility
  const [loading, setLoading] = useState(true);
  const [reRender, setReRender] = useState(false);
  const [iaService, setIaService] = useState(false);
  const [configMenuOpen, setConfigMenuOpen] = useState(false);
  const [displayAiAnalyticsDialog, setDisplayAiAnalyticsDialog] =
    useState(false);
  const [aiAnalyticsDialogOpenSource, setAiAnalyticsDialogOpenSource] =
    useState("dashboard");
  const [selectedReportItemFormAnalytics, setSelectedReportItemFormAnalytics] =
    useState();
  const ResponsiveReactGridLayout = useMemo(
    () => WidthProvider(Responsive),
    [reRender]
  );
  const [useMockData, setUseMockData] = useState(false);
  const [editMode, setEditMode] = useState(
    location.state?.startOnEdit || false
  );

  const [isInFullscreenMode, setIsInFullscreenMode] = useState(false);
  const [isInPreviewMode, setIsInPreviewMode] = useState(previewMode);
  const [isInExternalPreview, setIsInExternalPreview] = useState(external);
  const [isInAutoplayMode, setIsInAutoplayMode] = useState(false);
  const [refreshTimeCharts, setRefreshTimeCharts] = useState(0);

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] = useState(false);
  const [accessDialogOpen, setAccessDialogOpen] = useState(false);
  const [temporizerDialogOpen, setTemporizerDialogOpen] = useState(false);
  const [itemListDialogOpen, setItemListDialogOpen] = useState(false);

  // Other
  const navigate = useNavigate();
  const menuRef = useRef(null);
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);

  // Formik
  const formik = useFormik({
    initialValues: {
      name: "",
      dashboardAccess: [],
      useMockData: false,
    },
    validate: (values) => {
      let errors = {};
      if (!values) return {};
      if (!values.name || values.name.length < 1) {
        errors.name = LanguageProvider({ id: "gen.message.field.required" });
      }
      return errors;
    },
    onSubmit: (values) => saveDashboard(values),
  });

  const optionTemplate = (options, color, label, onClick = () => {}) => {
    return (
      <div
        className={classNames(options.className)}
        style={{ color, fontSize: "14px" }}
        onClick={onClick}
      >
        {LanguageProviderWithoutContext({ id: label, currentLanguage })}
      </div>
    );
  };

  const menuItems = [
    ...(userRoles.administratorOrInventoryManager ||
    userRoles.administrator ||
    userRoles.inventoryManager
      ? [
          {
            template: (item, options) =>
              optionTemplate(
                options,
                currentTheme.dashboardDataColor,
                "gen.edit.button",
                () => {
                  setEditMode(true);
                }
              ),
          },
        ]
      : []),
    {
      template: (item, options) =>
        optionTemplate(
          options,
          currentTheme.dashboardDataColor,
          "dashboard.configure.access",
          () => setAccessDialogOpen(true)
        ),
    },
    {
      template: (item, options) =>
        optionTemplate(
          options,
          currentTheme.dashboardDataColor,
          "dashboard.configure.temporizer",
          () => setTemporizerDialogOpen(true)
        ),
    },
    {
      template: (item, options) => {
        return (
          <div
            className={classNames(options.className)}
            style={{ color: currentTheme.dashboardDataColor, fontSize: "14px" }}
            onClick={() => {
              setUseMockData(!useMockData);
            }}
          >
            <div
              className="container"
              style={{
                display: "flex",
                gap: "6px",
                whiteSpace: "nowrap",
                width: "100%",
              }}
            >
              <span>
                {LanguageProviderWithoutContext({
                  id: "dashboard.use.mock.data",
                  currentLanguage,
                })}
              </span>
              <Checkbox
                style={{ marginLeft: "auto" }}
                checked={useMockData}
                onChange={(e) => {
                  setUseMockData(e.checked);
                  formik.setFieldValue("useMockData", e.checked);
                }}
              />
            </div>
          </div>
        );
      },
    },
    { separator: true },
    {
      template: (item, options) =>
        optionTemplate(options, "#F9485B", "dashboard.delete", () =>
          setDeleteConfirmationOpen(true)
        ),
    },
  ];

  const saveDashboard = async (values) => {
    try {
      const { data, status } = await dcpAxiosService.post(
        settings.Urls.Rest.Dashboard,
        {
          id: dashboard.id,
          isPublic: dashboard.isPublic,
          dashboardApplication: dashboard.dashboardApplication,
          name: values.name,
          dashboardAccess: values.dashboardAccess,
          useMockData: useMockData,
        },
        "Platform"
      );

      if (status === 200) {
        setDashboard({ ...dashboard, name: formik.values.name });
        showToast({
          severity: "success",
          message: <LanguageProvider id="dashboard.saved" />,
        });
        setAccessDialogOpen(false);
      } else {
        showToast({
          severity: "error",
          message: <LanguageProvider id={data.message} />,
        });
      }
    } catch (error) {
      console.error(error);
    }
    setEditModalOpen(false);
  };

  const handleCheckIAService = async () => {
    try {
      const checkIAServiceResponse = await checkAIService();
      if (checkIAServiceResponse.status) {
        setIaService(checkIAServiceResponse?.data);
      } else {
        showToast({
          severity: "error",
          message: (
            <LanguageProvider id={checkIAServiceResponse?.data?.message} />
          ),
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const saveDashboardItems = async () => {
    try {
      const { data, status } = await dcpAxiosService.post(
        settings.Urls.Rest.DashboardItem,
        dashboardItems,
        "Platform"
      );
      if (status === 200) {
        return true;
      } else {
        showToast({
          severity: "error",
          message: <LanguageProvider id={data.message} />,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onSave = async () => {
    try {
      if (loading) return;
      setLoading(true);
      setEditMode(false);
      await formik.submitForm();
      await saveDashboardItems();
      setReRender(!reRender);
      setLoading(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onDelete = async () => {
    setLoading(true);
    try {
      await dcpAxiosService.delete(settings.Urls.Rest.Dashboard, "Platform", {
        params: {
          id: dashboard.id,
        },
      });
      setDeleteConfirmationOpen(false);
      loadMenuItems();
      navigate(-1);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  const loadRoles = useCallback(async () => {
    const { data, status } = await dcpAxiosService.get(
      `${settings.Urls.Admin.Roles}/list`,
      "Auth",
      {
        params: {
          applicationClientId: applicationClientId,
        },
      }
    );

    const formattedRoles = data.data.map((role) => {
      return {
        ...role,
        code: `${LanguageProviderWithoutContext({
          id: role.description,
          currentLanguage,
        })} - ${role.code}`,
      };
    });

    if (status === 200) {
      setRoles(formattedRoles);
    }
  }, [dcpAxiosService]);

  const onClose = (e) => {
    setEditModalOpen(false);
    formik.resetForm();
  };

  const generateUniqueKey = () => {
    try {
      let key = (Math.floor(Math.random() * 9999) + 1).toString();
      const existingKey = dashboardItems.find((item) => item.gridKey === key);
      if (!existingKey) {
        return key;
      } else {
        generateUniqueKey();
      }
    } catch (error) {
      console.error(error);
    }
  };

  const loadDashboardItems = useCallback(
    async (id) => {
      try {
        const { data: itemsData, status: itemsStatus } =
          await dcpAxiosService.get(
            `${settings.Urls.Rest.DashboardItem}`,
            "Platform",
            {
              params: {
                idDashboard: id,
              },
            }
          );
        if (itemsStatus === 200) {
          setDashboardItems(itemsData.data);
        } else {
          showToast({
            severity: "error",
            message: <LanguageProvider id={itemsStatus.message} />,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
    [dcpAxiosService, showToast]
  );

  const loadDashboard = useCallback(async () => {
    try {
      if (!idDashboard && !isMain) return;
      setLoading(true);
      const { data, status } = await dcpAxiosService.get(
        settings.Urls.Rest.Dashboard,
        "Platform",
        {
          params: {
            idDashboard: !isMain && idDashboard,
            isMain: isMain,
            applicationClientId:
              isMain && product && product.idApplicationClient,
          },
        }
      );

      if (status === 200) {
        setDashboard(data.data);
        formik.setValues({
          name: data.data.name,
          dashboardAccess: data.data.dashboardAccess,
          useMockData: data.data.useMockData,
        });
        setUseMockData(data.data.useMockData === true);
        await loadDashboardItems(data.data.id);
      } else {
        showToast({
          severity: "error",
          message: <LanguageProvider id={status.message} />,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  }, [
    idDashboard,
    isMain,
    dcpAxiosService,
    product,
    formik,
    loadDashboardItems,
    showToast,
  ]);

  const loadItemTypes = useCallback(async () => {
    try {
      const { data, status } = await dcpAxiosService.get(
        settings.Urls.Rest.DashboardItem + "/list-types",
        "Platform"
      );
      if (status === 200) {
        setItemTypes(data.data);
      } else {
        showToast({
          severity: "error",
          message: <LanguageProvider id={data.message} />,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }, [showToast]);

  const onDuplicateItem = (item) => {
    try {
      const newItem = {
        ...item,
        gridKey: generateUniqueKey(),
        id: 0,
      };

      setDashboardItems([...dashboardItems, newItem]);
    } catch (error) {
      console.error(error);
    }
  };

  const onAddItem = (itemType) => {
    try {
      const type = itemTypes.find((it) => it.name === itemType);
      if (!type) return;

      const cols = 12;
      const totalItemsLength = dashboardItems.reduce(
        (prev, curr) => (curr.id >= 0 ? prev + curr.width : prev),
        0
      );

      const newItem = {
        id: 0,
        gridKey: generateUniqueKey(),
        dashboardId: dashboard.id,
        itemTypeId: type.id,
        width: type.minWidth,
        height: type.minHeight,
        posX: totalItemsLength % cols,
        posY: 0,
        isStatic: false,
        title: LanguageProviderWithoutContext({
          id: `dashboard.item.type.${type.name}`,
          currentLanguage,
        }),
        configValues: type.defaultConfigs.map((config) => {
          return {
            id: 0,
            value: config.defaultValue,
            itemTypeDefaultId: config.id,
            defaultConfig: config,
          };
        }),
      };
      setDashboardItems([...dashboardItems, newItem]);
      setItemListDialogOpen(false);
    } catch (error) {
      console.error(error);
    }
  };

  const onSaveConfig = (item) => {
    try {
      let newDashboardItems = [];
      dashboardItems.forEach((_item) => {
        if (_item.gridKey === item.gridKey) {
          newDashboardItems.push({
            ..._item,
            configValues: item.configValues,
            title: item.title,
          });
        } else {
          newDashboardItems.push(_item);
        }
      });
      setDashboardItems(newDashboardItems);
    } catch (error) {
      console.error(error);
    }
  };

  const onDeleteItem = (item) => {
    try {
      let newDashboardItems = [];
      dashboardItems.forEach((_item) => {
        if (_item.gridKey === item.gridKey) {
          if (_item.id > 0) {
            newDashboardItems.push({ ..._item, id: _item.id * -1 });
          }
        } else {
          newDashboardItems.push(_item);
        }
      });

      setDashboardItems(newDashboardItems);
    } catch (error) {
      console.error(error);
    }
  };

  const toggleStatic = (item) => {
    try {
      let _dashboardItems = dashboardItems;

      _dashboardItems.forEach((_item) => {
        if (item.gridKey === _item.gridKey) {
          _item.isStatic = !_item.isStatic;
        }
      });

      setDashboardItems(_dashboardItems);
      setReRender(!reRender);
    } catch (error) {
      console.error(error);
    }
  };

  const onLayoutChange = (layout) => {
    setDashboardItems(
      dashboardItems.map((item) => {
        let layoutItem = layout.find((li) => li.i === item.gridKey);

        if (layoutItem) {
          return {
            ...item,
            posX: layoutItem.x,
            posY: layoutItem.y,
            height: layoutItem.h,
            width: layoutItem.w,
          };
        } else {
          return item;
        }
      })
    );
  };

  const layout = (dashboardItems) => {
    if (dashboardItems && dashboardItems.length > 0) {
      return (
        <ResponsiveReactGridLayout
          onLayoutChange={onLayoutChange}
          compactType={null}
          preventCollision={true}
          margin={[20, 20]}
          breakpoints={{
            lg: 1200,
            md: 996,
            sm: 768,
            xs: 480,
            xxs: 0,
          }}
          cols={{ lg: 12, md: 10, sm: 6, xs: 4, xxs: 2 }}
        >
          {dashboardItems.map((item) => {
            if (!item || item.id < 0) return null;

            const gridItemTypes = GetMenuItems([]);
            if (!gridItemTypes || gridItemTypes.length === 0) return null;

            const type = itemTypes?.find((it) => it.id === item.itemTypeId);
            if (!type) return null;

            let gridItemComponent;
            for (let index = 0; index < gridItemTypes.length; index++) {
              const category = gridItemTypes[index];
              let gridItemType;
              if (category.items) {
                gridItemType = category.items.find((x) => x.type === type.name);
              }
              if (gridItemType) {
                gridItemComponent = gridItemType.component;
              }
            }

            if (!type || !gridItemComponent) return null;

            const isStatic = item.isStatic || isInPreviewMode;
            const gridItem = {
              i: item.gridKey,
              x: item.posX,
              y: item.posY,
              w: item.width,
              h: item.height,
              minH: type.minHeight,
              minW: type.minWidth,
              static: isStatic,
              isDraggable: !isStatic,
              isResizable: !isStatic,
            };

            return (
              <div key={gridItem.i} data-grid={gridItem}>
                <UseMockDataContext.Provider value={useMockData}>
                  <GridItem
                    disabledMenu={isInPreviewMode}
                    item={item}
                    type={type}
                    onStatic={toggleStatic}
                    onRefresh={() => {}}
                    onDelete={onDeleteItem}
                    onConfig={(item) => {
                      setSelectedItem(item);
                      setConfigMenuOpen(true);
                    }}
                    onDuplicate={(item) => onDuplicateItem(item)}
                    gridItemComponent={gridItemComponent}
                    onAiAnalysis={(item) => handleReportItemAiAnalysis(item)}
                    inEditMode={editMode}
                  />
                </UseMockDataContext.Provider>
              </div>
            );
          })}
        </ResponsiveReactGridLayout>
      );
    }
  };

  function handleAiAnalyticsDialogOpen() {
    setAiAnalyticsDialogOpenSource("dashboard");
    setDisplayAiAnalyticsDialog(true);
  }
  function handleAiAnalyticsDialogClose() {
    setDisplayAiAnalyticsDialog(false);
  }
  function handleReportItemAiAnalysis(reportItem) {
    if (reportItem) {
      setAiAnalyticsDialogOpenSource("report-item");
      setDisplayAiAnalyticsDialog(true);
      setSelectedReportItemFormAnalytics(reportItem);
    }
  }
  const fetchSavedDashboardTimers = async () => {
    try {
      const { data, status } = await dcpAxiosService.get(
        `${settings.Urls.Rest.Dashboard}/get-dashboard-timers/`,
        "Platform",
        {
          params: {
            dashboardId: dashboard?.id,
          },
        }
      );
      if (data.status) {
        await setRefreshTimeCharts(data.data.chartsAutorefreshTimeInSeconds);
      } else {
        console.error("Error while fetching saved dashboard timers");
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setIsInPreviewMode(previewMode || external);
    setIsInExternalPreview(external);
    loadDashboard();
    handleCheckIAService();
  }, [idDashboard, product]); // adding all the necessary dependencies will cause a render loop

  useEffect(() => {
    fetchSavedDashboardTimers();
  }, [reRender, isInAutoplayMode]);

  useEffect(() => {
    fetchSavedDashboardTimers();
    if (refreshTimeCharts > 0 && editMode === false) {
      const contentRefreshInterval = setInterval(async () => {
        await loadDashboardItems(dashboard.id);
      }, refreshTimeCharts * 1000);

      return () => clearInterval(contentRefreshInterval);
    }
  }, [refreshTimeCharts, editMode, dashboard?.id]);

  useEffect(() => {
    async function load() {
      setLoading(true);
      await loadItemTypes();
      await loadRoles();
      setInitialLoadComplete(true);
      setLoading(false);
    }
    if (!initialLoadComplete) load();
  }, [loadItemTypes, loadRoles, initialLoadComplete]);

  const headerButtons = {
    save: (
      <Button onClick={onSave} loading={loading}>
        <LanguageProvider id="gen.save.button" />
      </Button>
    ),
    fullscreen: (
      <Button
        appearance="secondary"
        className="menu-btn p-button-plain"
        onClick={() => {
          setIsInFullscreenMode(!isInFullscreenMode);
          const element = document.getElementById("root");
          if (isInFullscreenMode) document.webkitExitFullscreen();
          else element.requestFullscreen();
        }}
      >
        <Icon
          icon={isInFullscreenMode ? "minimize-01" : "maximize-01"}
          className="btn-icon-fullscreen"
        ></Icon>
      </Button>
    ),
    ai: (
      <>
        {userRoles.administratorOrInventoryManager ||
        userRoles.administrator ||
        userRoles.inventoryManager
          ? iaService && (
              <Button
                className="btn-ia-analytic"
                appearance="primary"
                onClick={handleAiAnalyticsDialogOpen}
                label={<LanguageProvider id={"gen.ai"} />}
              >
                <Icon
                  icon={"lightbulb-02"}
                  className="btn-ia-icon"
                  color={currentTheme.white}
                />
              </Button>
            )
          : null}
      </>
    ),
    menu: (
      <Button
        appearance="secondary"
        onClick={(e) => menuRef.current.toggle(e)}
        className="menu-btn p-button-plain"
      >
        <Icon icon="dots-vertical" />
      </Button>
    ),
    previewEdit: (
      <Button
        severity="secondary"
        onClick={() => {
          navigate(`/dashboard-graphs/${dashboard.id}`, {
            state: { startOnEdit: true },
          });
        }}
      >
        <LanguageProvider id="dashboard.edit" />
      </Button>
    ),
    cancelPreviewEdit: (
      <Button
        severity="secondary"
        onClick={() => {
          navigate(-1);
        }}
        className="cancel-edit-button"
      >
        <LanguageProvider id="gen.cancel.button" />
      </Button>
    ),
    cancelEdit: (
      <Button
        severity="secondary"
        onClick={() => {
          setEditMode(false);
          // document.location.reload();
        }}
      >
        <LanguageProvider id="gen.cancel.button" />
      </Button>
    ),
    addItems: (
      <Button
        onClick={() => setItemListDialogOpen(true)}
        loading={loading}
        buttonStyle="outline"
        outlined
        className="add-item-button"
      >
        <LanguageProvider id="dashboard.add.item" />
        <Icon icon="plus-circle" className="dashboard-add-item-icon" />
      </Button>
    ),
  };

  function EditModeButtons() {
    return (
      <>
        {headerButtons.addItems}
        {headerButtons.cancelEdit}
        {headerButtons.save}
        {headerButtons.menu}
      </>
    );
  }

  function ExternalDashboardHeaderButtons() {
    return (
      <>
        {headerButtons.fullscreen}
        {headerButtons.ai}
      </>
    );
  }

  function MainDashboardHeaderButtons() {
    return editMode ? (
      <EditModeButtons />
    ) : (
      <>
        {isInPreviewMode && <>{headerButtons.previewEdit}</>}
        {!isInPreviewMode && <>{headerButtons.menu}</>}
      </>
    );
  }

  function RegularDashboardHeaderButtons() {
    return editMode ? <EditModeButtons /> : headerButtons.menu;
  }

  function getHeaderButtons() {
    if (isInExternalPreview) return <ExternalDashboardHeaderButtons />;
    else if (isMain) return <MainDashboardHeaderButtons />;
    else return <RegularDashboardHeaderButtons />;
  }

  return (
    <>
      {!loading ? (
        <>
          {dashboard && (
            <div
              className={`dashboard-graphs ${isInFullscreenMode ? "full" : ""}`}
              style={{
                height: isInFullscreenMode
                  ? "100%"
                  : `calc(100vh - ${getHeaderSize()}px)`,
              }}
            >
              <div className="main-content">
                <header>
                  <h3 className="dashboard-name">
                    {editMode ? (
                      <InputText
                        value={formik.values.name}
                        onChange={(e) =>
                          formik.setFieldValue("name", e.target.value)
                        }
                        className="dashboard-name-input"
                        style={{
                          color: "var(--text)",
                        }}
                      />
                    ) : (
                      formik.values.name
                    )}
                  </h3>
                  <div className="actions">
                    {headerButtons.fullscreen}
                    {headerButtons.ai}
                    {getHeaderButtons()}
                  </div>
                </header>
                <div id="grid-layout-container" className="content-container">
                  {dashboardItems && dashboardItems.length > 0 && !loading ? (
                    layout(dashboardItems)
                  ) : (
                    <div className="placeholder-container">
                      <div className="placeholder">
                        <EmptyReportSVG />
                        <p className="title">
                          <LanguageProvider id="dashboard.empty.title" />
                        </p>
                        <p className="description">
                          <LanguageProvider id="dashboard.empty.description" />
                        </p>
                        <Button
                          className="add-item"
                          onClick={() => {
                            setEditMode(true);
                            setItemListDialogOpen(true);
                          }}
                        >
                          <LanguageProvider id="dashboard.add.item" />
                          <Icon icon="plus-circle" color="white" />
                        </Button>
                      </div>
                    </div>
                  )}
                </div>
                {!isInPreviewMode && !isInFullscreenMode && (
                  <ItemListDialog
                    open={itemListDialogOpen}
                    onClose={() => setItemListDialogOpen(false)}
                    onAddItem={onAddItem}
                  />
                )}
              </div>
              <ConfigSideMenu
                isOpen={configMenuOpen}
                item={selectedItem}
                onCancel={() => setConfigMenuOpen(false)}
                onSave={(item) => {
                  onSaveConfig(item);
                  setConfigMenuOpen(false);
                }}
              />
              <Menu
                popup
                ref={menuRef}
                model={menuItems}
                style={{ minWidth: "300px" }}
              />
            </div>
          )}
        </>
      ) : (
        <LoadingIndicator />
      )}
      <Dialog
        visible={editModalOpen}
        onHide={onClose}
        headerStyle={{ padding: "20px" }}
        contentStyle={{
          paddingTop: "20px",
          borderBottom: "var(--defaultBorder)",
          borderTop: "var(--defaultBorder)",
        }}
        header={
          <span className="dashboards-header">
            <LanguageProvider id="dashboard.edit" />
          </span>
        }
        footer={
          <span>
            <Button
              text
              style={{ marginTop: "20px" }}
              className="p-button-plain"
              label={<LanguageProvider id="gen.cancel" />}
              onClick={onClose}
              link
            ></Button>
            <Button
              label={<LanguageProvider id="gen.save.button" />}
              onClick={(e) => {
                formik.submitForm();
              }}
              loading={loading}
            ></Button>
          </span>
        }
      >
        <Form style={{ padding: 0 }} onClick={(e) => e.stopPropagation()}>
          <FormRow>
            <InputContainer label={<LanguageProvider id="gen.name" />}>
              <InputText
                width="700px"
                value={formik.values.name}
                style={{ minWidth: "540px" }}
                onChange={(e) => formik.setFieldValue("name", e.target.value)}
              />
              {formik.touched.name && formik.errors.name ? (
                <small className="p-error">{formik.errors.name}</small>
              ) : (
                <small className="p-error">&nbsp;</small>
              )}
            </InputContainer>
          </FormRow>
        </Form>
      </Dialog>
      <DashboardAccessDialog
        visible={accessDialogOpen}
        onCancel={() => setAccessDialogOpen(false)}
        model={dashboard}
        onSave={(e) => {
          formik.setFieldValue("dashboardAccess", e);
          formik.submitForm();
        }}
        roles={roles}
        loading={loading}
      />
      <ModalSettingsDashboardTimers
        isOpen={temporizerDialogOpen}
        dashboardId={idDashboard}
        onCancel={(e) => {
          setTemporizerDialogOpen(false);
          setReRender(!reRender);
        }}
      />
      <ModalConfirmation
        isOpen={deleteConfirmationOpen}
        isDelete={true}
        modalTitle={<LanguageProvider id="dashboard.delete" />}
        bodyMessage={<LanguageProvider id="dashboard.delete.msg" />}
        onCancel={(e) => {
          setDeleteConfirmationOpen(false);
        }}
        onConfirm={onDelete}
        loading={loading}
      />
      {dashboard && (
        <AiAnalytics
          visible={displayAiAnalyticsDialog}
          onClose={handleAiAnalyticsDialogClose}
          idDashboard={dashboard.id}
          dashboardItemTypes={itemTypes}
          openingSource={aiAnalyticsDialogOpenSource}
          dashboardItem={
            selectedReportItemFormAnalytics
              ? selectedReportItemFormAnalytics
              : null
          }
        />
      )}
    </>
  );
}

const getHeaderSize = () => {
  try {
    let height = 0;
    const navMenu = document.querySelector(".nav-menu");
    const navContainer = document.querySelector(
      ".product-navigation-container"
    );
    if (navContainer) height += navContainer.offsetHeight;
    if (navMenu) height += navMenu.offsetHeight;
    return height;
  } catch (error) {
    console.error(error);
  }
};
