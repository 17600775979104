import React, { useState } from "react";
import { useEffect } from "react";
import InitialSetup from "~/pages/shared-modules/databases/initial-setup/initial-setup";
import DatabaseList from "~/pages/shared-modules/databases/list/database-list";
import UseDatabaseProductProcess from "~/pages/wms/hooks/database-product-process";
import settings from "~/services/settings.json";

const SKUSInventory = () => {
  const { databaseProductProcessData, loadDatabaseProductProcess } =
    UseDatabaseProductProcess();

  useEffect(() => {
    loadDatabaseProductProcess(settings.DatabaseProcessType.Inventory_SKU);
  }, [loadDatabaseProductProcess]);

  return (
    <>
      {databaseProductProcessData?.status &&
        (databaseProductProcessData !== null ? (
          <DatabaseList
            id={databaseProductProcessData?.idDatabase}
            hasDetails={databaseProductProcessData?.hasDetails}
            uriDetails={databaseProductProcessData?.uriDetails}
            showIcon={false}
          />
        ) : (
          <InitialSetup />
        ))}
    </>
  );
};

export default SKUSInventory;
