import "./scss/auth-side-menu.scss";

import { PanelMenu } from "primereact/panelmenu";
import { useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Icon from "../components/icons";
import LanguageProvider from "../components/language-provider";
import { checkAIService } from "~/services/api";
import { useLanguageContext } from "~/context/LanguageContext";
import LanguageProviderWithoutContext from "../components/language-provider-without-context";

const AuthSideMenu = () => {
  const { currentLanguage } = useLanguageContext();
  const [menuItems, setMenuItems] = useState([]);

  const createMenuItems = (AIServiceEnabled) => {
    return [
      {
        key: "auth-settings",
        label: LanguageProviderWithoutContext({
          id: "dcp.auth.side.menu.settings",
          currentLanguage,
        }),
        expanded: true,
        items: [
          {
            key: "auth-settings-users",
            url: "users",
            label: LanguageProviderWithoutContext({
              id: "dcp.auth.side.menu.settings.users",
              currentLanguage,
            }),
            template: DcpNavMenuItemTemplate,
          },
          {
            key: "auth-settings-integrations",
            url: "integrations",
            label: LanguageProviderWithoutContext({
              id: "dcp.auth.side.menu.settings.integrations",
              currentLanguage,
            }),
            template: DcpNavMenuItemTemplate,
          },
          // {
          //   key: "auth-settings-grafana",
          //   url: "grafana",
          //   label: LanguageProvider({
          //     id: "dcp.auth.side.menu.settings.grafana",
          //   }),
          //   template: DcpNavMenuItemTemplate,
          // },
          {
            key: "auth-settings-data-management",
            url: "data-management",
            label: LanguageProviderWithoutContext({
              id: "dcp.auth.side.menu.settings.data.management",
              currentLanguage,
            }),
            template: DcpNavMenuItemTemplate,
          },
          AIServiceEnabled && {
            key: "auth-settings-gpt-ai",
            url: "gpt-ai",
            label: LanguageProviderWithoutContext({
              id: "dcp.auth.side.menu.settings.gpt-ai",
              currentLanguage,
            }),
            template: DcpNavMenuItemTemplate,
          },
        ],
        template: DcpNavMenuItemTemplate,
      },
    ];
  };

  useEffect(() => {
    async function checkAIUserService() {
      try {
        const checkAIUserServiceResponse = await checkAIService();
        if (checkAIUserServiceResponse.status) {
          setMenuItems(createMenuItems(checkAIUserServiceResponse.data));
        } else {
          console.error("Error checking AI service");
        }
      } catch (error) {
        console.error(error);
        setMenuItems(createMenuItems(false));
      }
    }
    checkAIUserService();
  }, []);

  return (
    <div className="auth-side-menu">
      <div className="side-menu-container">
        <div className="navigation">
          <PanelMenu className="dcp-panel-menu" model={menuItems} multiple />
        </div>
      </div>
    </div>
  );
};

const DcpNavMenuItemTemplate = (item, options) => {
  return (
    <NavLink
      to={item.url}
      className={(isActive, isPending, isTransitioning) =>
        [
          options.className,
          isActive ? "active" : "",
          isPending ? "pending" : "",
          isTransitioning ? "transitioning" : "",
        ].join(" ")
      }
      role="menuitem"
      onClick={options.onClick}
    >
      {item.items && (!item.iconPosition || item.iconPosition === "start") && (
        <span className={options.submenuIconClassName} />
      )}
      {item.icon && (
        <Icon className={options.iconClassName} icon={item.icon} size={16} />
      )}
      <span className={`dcp-menu-item-label ${options.labelClassName}`}>
        {item.label}
      </span>
      {item.items && item.iconPosition === "end" && (
        <span className={options.submenuIconClassName} />
      )}
    </NavLink>
  );
};

export default AuthSideMenu;
