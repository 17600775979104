import settings from "~/services/settings.json";
import DcpAxiosService from "~/services/axios/dcp-axios-service";
import {
  DeviceConnectionModel,
  LabelPrinterConnectionModel,
  LabelPrintModel,
  ProviderConnectionModel,
} from "~/shared/interfaces";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

export async function GetZplSerialPrintCode(
  model: LabelPrintModel
): Promise<any> {
  try {
    var { data, status } = await axiosService().post(
      settings.Urls.Rest.PrintZpl + "/get-zpl-code",
      model,
      "Print"
    );

    return data;
  } catch (error) {
    console.error(error);
  }
}

export async function GetPrinters(): Promise<LabelPrinterConnectionModel[]> {
  try {
    var { data, status } = await axiosService().get(
      settings.Urls.Rest.PrintZpl + "/printers",
      "Print"
    );

    if (status === 200 && data.data) {
      return data.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}
export async function GetDevices(): Promise<DeviceConnectionModel[]> {
  try {
    var { data, status } = await axiosService().get(
      settings.Urls.Rest.PrintZpl + "/devices",
      "Print"
    );

    if (status === 200 && data.data) {
      return data.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}

export async function GetProviders(): Promise<ProviderConnectionModel[]> {
  try {
    var { data, status } = await axiosService().get(
      settings.Urls.Rest.PrintZpl + "/providers",
      "Print"
    );

    if (status === 200 && data.data) {
      return data.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}
