import "./scss/picking-multi.scss";
import { useContext, useEffect, useRef, useState } from "react";
import React from "react";
import LanguageProvider from "~/shared/components/language-provider";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import { Dropdown } from "primereact/dropdown";
import Icon from "~/shared/components/icons";
import PageHeader from "~/shared/components/page-header/page-header";
import { ThemeContext } from "~/app";
import { InputText } from "primereact/inputtext";
import { ListPickingWorktations as listPickingWorktations } from "~/services/api";
import { Button } from "~/shared/components/dcp-button";
import axios from "axios";
import { useToastContext } from "~/context/ToastContext";
import CommandModalPicking from "../components/command-modal";
import { Dialog } from "primereact/dialog";

export default function WMSCoreCevaPickingMulti() {
  const { currentTheme } = useContext(ThemeContext);
  const skuRef = useRef(null);
  const nfRef = useRef(null);
  const serialRef = useRef(null);
  const { showToast } = useToastContext();
  const api = axios.create({
    headers: { "content-type": "application/json" },
    baseURL: "http://localhost:5127/api/telnet/wms/core",
  });

  // Data
  const [workStations, setWorkStations] = useState<any[]>();
  const [selectedWorkstation, setSelectedWorkstation] = useState();
  const [sku, setSku] = useState("");
  const [nf, setNf] = useState("");
  const [serialNumber, setSerialNumber] = useState("");
  const [nextSku, setNextSku] = useState("");
  const [openConsole, setOpenConsole] = useState(false);

  // View Controll
  const [fullScreen, setFullScreen] = useState(false);
  const [loadingWorkstations, setLoadingWorkstations] = useState(true);

  // Input Block
  const [nfBlocked, setNfBlocked] = useState(false);
  const [skuBlocked, setSkuBlocked] = useState(true);
  const [serialNumberBlocked, setSerialNumberBlocked] = useState(true);

  function headerActions() {
    return (
      <div className="icon" onClick={() => setFullScreen(!fullScreen)}>
        <Icon
          icon={fullScreen ? "minimize-01" : "maximize-01"}
          size={18}
          color={currentTheme.iconDefaultColor}
        />
      </div>
    );
  }

  async function onKeyDownNf(e) {
    try {
      if (e.code !== "Tab" && e.code !== "Enter") return;
      e.preventDefault();
      if (nf.length === 0) return;

      const { data, status } = await api.post("/multi/first", {
        speed: 4000,
        userName: "BERNASCONF",
        password: "v98SIICevb4359",
        wmsHostName: "matrix-wms-02U",
        wmsPort: "45908",
        nf: nf,
        serial: "",
        sku: "",
        menuCode: "2313",
      });

      if (status === 200 && nf.length > 0) {
        if (data.validaProcess) {
          setNfBlocked(true);
          setSkuBlocked(false);
          setSerialNumberBlocked(false);
          setNextSku(data.sku);
          skuRef.current.disabled = false;
          skuRef.current.focus({ focusVisible: true });
        } else {
          showToast({
            severity: "error",
            message: data.message,
          });
        }
      }
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message: error,
      });
    }
  }

  function onBlurSku(e) {
    if (e.code !== "Tab" && e.code !== "Enter") return;
    e.preventDefault();

    if (sku.length === 0) return;
    serialRef.current.focus();
  }

  async function onBlurSerialNumber(e) {
    try {
      if (e.code !== "Tab" && e.code !== "Enter") return;
      e.preventDefault();
      if (serialNumber.length === 0) return;

      const { data, status } = await api.post("/multi/second", {
        speed: 4000,
        userName: "BERNASCONF",
        password: "v98SIICevb4359",
        wmsHostName: "matrix-wms-02U",
        wmsPort: "45908",
        nf: nf,
        serial: serialNumber,
        sku: sku,
        menuCode: "2313",
      });

      if (status === 200) {
        if (!data.validaProcess) {
          showToast({
            severity: "error",
            message: data.message,
          });
          setSku("");
          setSerialNumber("");
          skuRef.current.focus();
          return;
        }

        if (data.processFinish && data.sku.length === 0) {
          setNextSku("");
          setSerialNumber("");
          setSku("");
          setNf("");
          setNfBlocked(false);
          nfRef.current.disabled = false;
          nfRef.current.focus();
          setSkuBlocked(true);
          setSerialNumberBlocked(true);
          showToast({
            serverity: "sucess",
            message: <LanguageProvider id="" />,
          });
        } else {
          setNextSku(data.sku);
          setSku("");
          setSerialNumber("");
          skuRef.current.focus();
        }
      }
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message: error,
      });
    }
  }

  async function loadWorkStations() {
    try {
      var response = await listPickingWorktations();
      setWorkStations(response);
      if (response.length > 0 && !selectedWorkstation)
        setSelectedWorkstation(response[0]);
    } catch (error) {
      console.error(error);
    }
  }

  // Telnet request
  async function validateLoginTelnet() {
    try {
      const { status } = await api.post("/auth/login", {
        speed: 4000,
        userName: "BERNASCONF",
        password: "v98SIICevb4359",
        wmsHostName: "matrix-wms-02U",
        wmsPort: "45908",
        nf: "",
        serial: "",
        sku: "",
        menuCode: "2313",
      });

      if (status === 200) {
        showToast({
          severity: "success",
          message: <LanguageProvider id="gen.success" />,
        });
      }
    } catch (error) {
      console.error(error);
    }
  }

  // Initial values load
  useEffect(() => {
    async function load() {
      setLoadingWorkstations(true);
      await loadWorkStations();
      setLoadingWorkstations(false);
    }

    load();
  }, []);

  return (
    <div className={`picking-main-container ${fullScreen ? "fullscreen" : ""}`}>
      <Dialog
        visible={openConsole}
        header={LanguageProvider({ id: "dcp.wms.core.picking.console" })}
        className="remove-header-dialog"
        position={"top"}
        style={{ width: "100%", maxWidth: "800px" }}
        onHide={() => setOpenConsole(false)}
      >
        <CommandModalPicking />
      </Dialog>

      <div className="backdrop">
        <PageHeader
          title={
            <LanguageProvider id="dcp.side.menu.picking.multi.wms.core" />
          }
          titleTemplate={undefined}
          recordsCount={undefined}
          onReturn={undefined}
          actions={headerActions}
        ></PageHeader>
        <div className="content-container">
          <div className="fields-container">
            <Form>
              <FormRow>
                <InputContainer
                  label={
                    <LanguageProvider id="dcp.side.menu.estacao.wms.core" />
                  }
                >
                  <Dropdown
                    options={workStations}
                    loading={loadingWorkstations}
                    value={selectedWorkstation}
                    onChange={(e) => setSelectedWorkstation(e.value)}
                    filter
                  ></Dropdown>
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer
                  label={<LanguageProvider id="datasource.column.nf" />}
                >
                  <InputText
                    ref={nfRef}
                    value={nf}
                    onChange={(e) => setNf(e.target.value)}
                    disabled={nfBlocked}
                    onKeyDown={onKeyDownNf}
                  ></InputText>
                </InputContainer>
              </FormRow>
              <h3>
                <LanguageProvider id="core.ceva.picking.next.sku" />: {nextSku}
              </h3>
              <FormRow>
                <InputContainer label={<LanguageProvider id="gen.sku" />}>
                  <InputText
                    value={sku}
                    onChange={(e) => setSku(e.target.value)}
                    ref={skuRef}
                    disabled={skuBlocked}
                    onKeyDown={onBlurSku}
                    autoFocus
                  ></InputText>
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer
                  label={<LanguageProvider id="devices.drones.serial" />}
                >
                  <InputText
                    value={serialNumber}
                    ref={serialRef}
                    onChange={(e) => setSerialNumber(e.target.value)}
                    disabled={serialNumberBlocked}
                    onKeyDown={onBlurSerialNumber}
                  ></InputText>
                  {/* Fake input to prevent focus from moving out of the page */}
                  <input
                    style={{ width: "0", height: "0", border: "none", background: "transparent" }}
                  ></input>
                </InputContainer>
              </FormRow>
            </Form>
          </div>
        </div>
        <div className="footer-container">
          <div>
            <Button appearance="secondary" onClick={() => validateLoginTelnet()}>
              Validar login WMS
            </Button>
            <Button appearance="secondary" onClick={() => setOpenConsole(true)}>
              Console
            </Button>
          </div>
          <div>
            <Button
              appearance="secondary"
              onClick={() => {
                setNf("");
                setSerialNumber("");
                setSku("");
              }}
            >
              <LanguageProvider id="dcp.platform.warehouse.clean.data" />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
