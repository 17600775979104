import "./CardOrdersChart.scss";
import settings from "~/services/settings.json";
import React, { useContext, useEffect, useRef, useState } from "react";
import OrderIconError from "~/theme/media/assets/Order-icon-error.svg";
import OrderIconWarning from "~/theme/media/assets/Order-icon-warning.svg";
import OrderIconDefault from "~/theme/media/assets/Order-icon-default.svg";
import { ThemeContext } from "~/app";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";

export default function CardOrdersChart() {
  const { currentTheme } = useContext(ThemeContext);
  const dcpAxiosService = useDcpAxiosService();
  const useMockData = useContext(UseMockDataContext);
  const containerRef = useRef(null);
  const [isSmall, setIsSmall] = useState(false);
  const [cards, setCards] = useState([]);

  const cardsFakeData = [
    {
      allocatedOrdersTotalCount: 25,
      allocatedDaysCount: 3,
    },
    {
      allocatedOrdersTotalCount: 6,
      allocatedDaysCount: 2,
    },
    {
      allocatedOrdersTotalCount: 14,
      allocatedDaysCount: 1,
    },
  ];

  const iconAllocatedOrder = (index) => {
    try {
      switch (index) {
        case 0:
          return OrderIconDefault;
        case 1:
          return OrderIconWarning;
        case 2:
          return OrderIconError;
      }
    }
    catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (useMockData) {
      setCards(cardsFakeData);
    } else {
      const getAllocatedOrders = async () => {
        try {
          const { data, status } = await dcpAxiosService.get(
            settings.Urls.Rest.DashboardItem + "/list-allocated-orders-count",
            "StageControl"
          );
          if (status === 200) {
            setCards(
              data.data.map((item) => ({
                allocatedOrdersTotalCount: item.allocatedOrdersTotalCount,
                allocatedDaysCount: item.allocatedDaysCount,
              }))
            );
          }
        } catch (error) {
          console.error(error);
        }
      };

      getAllocatedOrders();
    }
  }, [useMockData]);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      for (let entry of entries) {
        const width = entry.contentRect.width;
        setIsSmall(width < 500);
      }
    });

    if (containerRef.current) {
      observer.observe(containerRef.current);
    }

    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, []);

  return (
    <div
      ref={containerRef}
      className={`card-orders-chart-container ${isSmall ? "small" : "large"}`}
    >
      <div className="allocated-orders">
        <div className="card-order-container">
          {cards.map((card, index) => (
            <div
              key={index}
              className="card-order"
              style={{ borderColor: currentTheme.systemBackground }}
            >
              <div className="card-order-icon">
                <img
                  className="card-order-icon-image"
                  src={iconAllocatedOrder(index)}
                  alt="icon"
                />
              </div>
              <div className="card-order-content">
                <span className="card-order-value">
                  {card?.allocatedOrdersTotalCount}
                </span>
                <span className="card-order-description">
                  +{card?.allocatedDaysCount} dias
                </span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
