import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import { useContext, useEffect, useState } from "react";
import { ThemeContext } from "~/app";
import { useMenuItemsContext } from "~/context/MenuItemsContext";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import { Button } from "~/shared/components/dcp-button";
import settings from "~/services/settings.json";
import { getLoggedUserAllowances } from "~/services/api";

const InitialSetup = ({}) => {
  const dcpAxiosService = useDcpAxiosService();
  const { currentTheme } = useContext(ThemeContext);
  const { databases, loadMenuItems } = useMenuItemsContext();
  const [isSuperUser, setIsSuperUser] = useState(false);
  const [DatabaseMainDialogVisible, setDatabaseMainDialogVisible] =
    useState(false);
  const [selectedDatabase, setSelectedDatabase] = useState(0);

  const handleSetHomeDatabase = async () => {
    const applicationId = propertyFrommStorage("authentication", "clientId");
    try {
      const response = await dcpAxiosService.post(
        settings.Urls.Rest.Databases +
          "/set-main-database?idDatabase=" +
          selectedDatabase +
          "&applicationClient=" +
          applicationId,
        {},
        "Platform"
      );

      if (response.data) {
        setDatabaseMainDialogVisible(false);
        window.location.reload();
      }
    } catch (error) {
      console.error(error);
    }
  };
  const getUserData = async () => {
    try {
      const getUserResponse = await getLoggedUserAllowances();
      if (getUserResponse.status) {
        setIsSuperUser(getUserResponse.data);
      }
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    loadMenuItems();
    getUserData();
  }, []);

  return (
    <>
      {isSuperUser && (
        <>
          <div className="configure-Database" style={{ padding: "26px" }}>
            <h3>Configurar Database inicial</h3>
            <Button
              label="Configurar Database"
              onClick={() => setDatabaseMainDialogVisible(true)}
            />
          </div>
        </>
      )}
      <Dialog
        visible={DatabaseMainDialogVisible}
        onHide={() => setDatabaseMainDialogVisible(false)}
        header="Configurar Database inicial"
        contentStyle={{
          width: "70vw",
          backgroundColor: currentTheme.systemBackground,
        }}
        content={
          <div
            style={{
              backgroundColor: currentTheme.systemBackground,
              height: "200px",
              width: "400px",
              margin: "0 auto",
              padding: "20px",
              borderRadius: "12px",
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <h3>Configurar Database</h3>
            <Dropdown
              optionLabel="name"
              optionValue="id"
              value={selectedDatabase}
              onChange={(e) => setSelectedDatabase(e.value)}
              options={databases}
              placeholder="Selecione o database"
            />
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              <Button
                label="Cancelar"
                appearance="secondary"
                onClick={() => setDatabaseMainDialogVisible(false)}
              />
              <Button label="Salvar" onClick={handleSetHomeDatabase} />
            </div>
          </div>
        }
      />
    </>
  );
};

export default InitialSetup;
