import "../../scss/database-edit-field.scss";

import {
  InputContainer,
  InputSwitchContainer,
} from "~/shared/components/dcp-form";
import { useContext, useEffect, useState } from "react";

import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import Icon from "~/shared/components/icons";
import { InputSwitch } from "primereact/inputswitch";
import { InputText } from "primereact/inputtext";
import { InputTextarea } from "primereact/inputtextarea";
import LanguageProvider from "~shared/components/language-provider";
import { classNames } from "primereact/utils";
import { useFormik } from "formik";
import { listDatalakeDatabases } from "~/services/api";
import { ThemeContext } from "~/app";
import LanguageProviderWithoutContext from "~/shared/components/language-provider-without-context";
import { useLanguageContext } from "~/context/LanguageContext";

export const EditFieldModal = ({
  isEditModalVisible,
  model,
  fieldInput,
  onSave,
  onClose,
}) => {
  const { currentTheme } = useContext(ThemeContext);
  const { currentLanguage } = useLanguageContext();
  const [showMultipleOptions, setShowMultipleOptions] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [newItem, setNewItem] = useState("");
  const textMasks = [
    {
      label: LanguageProviderWithoutContext({
        id: "database.field.mask.label.email",
        currentLanguage,
      }),
      name: "email",
    },
    {
      label: LanguageProviderWithoutContext({
        id: "database.field.mask.label.phone",
        currentLanguage,
      }),
      name: "phone",
    },
    {
      label: LanguageProviderWithoutContext({
        id: "database.field.mask.label.licensePlate",
        currentLanguage,
      }),
      name: "licensePlate",
    },
  ];
  const numberMasks = [
    {
      label: LanguageProviderWithoutContext({
        id: "database.field.mask.label.currency",
        currentLanguage,
      }),
      name: "currency",
    },
    {
      label: LanguageProviderWithoutContext({
        id: "database.field.mask.label.kilos",
        currentLanguage,
      }),
      name: "kilos",
    },
    {
      label: LanguageProviderWithoutContext({
        id: "database.field.mask.label.grams",
        currentLanguage,
      }),
      name: "grams",
    },
    {
      label: LanguageProviderWithoutContext({
        id: "database.field.mask.label.temperature",
        currentLanguage,
      }),
      name: "temperature",
    },
    {
      label: LanguageProviderWithoutContext({
        id: "database.field.mask.label.integer",
        currentLanguage,
      }),
      name: "integer",
    },
    {
      label: LanguageProviderWithoutContext({
        id: "database.field.mask.label.decimal",
        currentLanguage,
      }),
      name: "decimal",
    },
  ];
  const [databaseOptions, setDatabaseOptions] = useState([]);

  async function loadDatabases() {
    try {
      const databases = await listDatalakeDatabases();
      setDatabaseOptions(
        databases.map((db) => {
          return { id: db.id, name: db.name };
        })
      );
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    loadDatabases();
  }, []);

  const closeModal = () => {
    if (onClose) {
      onClose();
    }
  };

  const fieldSettingsFormik = useFormik({
    initialValues: {
      id: 0,
      required: model.required,
      hasTextHelp: model.hasTextHelp,
      textHelp: model.textHelp,
      hasDescription: model.hasDescription,
      description: model.description,
      hasInputMask: model.hasInputMask,
      inputMaskName: model.inputMaskName,
      typeDatabaseId: model.typeDatabaseId,
      type: model.type,
      viewName: model.viewName,
      idTypeValue: model.idTypeValue,
      idDatabase: model.idDatabase,
      options: model.options,
      iconName: model.iconName,
      sequence: model.sequence,
      originalLabel: model.originalLabel,
    },
    validateOnChange: false,
    validate: (data) => {
      let errors = {};
      if (!data.viewName) {
        errors.viewName = (
          <LanguageProvider id={"field.view.name.database.preview.error"} />
        );
      }

      if (data.hasTextHelp && data.textHelp.length < 1) {
        errors.textHelp = (
          <LanguageProvider id={"field.view.name.database.preview.error"} />
        );
      }

      if (data.hasDescription && data.description.length < 1) {
        errors.description = (
          <LanguageProvider id={"field.view.name.database.preview.error"} />
        );
      }

      return errors;
    },
    onSubmit: (values) => {
      setSubmitting(true);
      try {
        onSave(values);
        fieldSettingsFormik.resetForm();
      } catch (error) {
        console.error(error);
      }
      setSubmitting(false);
    },
  });

  const IsFieldSettingsFormValid = (viewName) => {
    return !!fieldSettingsFormik.errors[viewName];
  };
  const GetFieldSettingsFormErrorMessage = ({ viewName }) => {
    return IsFieldSettingsFormValid(viewName) ? (
      <small className="p-error">{fieldSettingsFormik.errors[viewName]}</small>
    ) : (
      <small className="p-error">&nbsp;</small>
    );
  };

  const addMultipleOption = () => {
    if (showMultipleOptions === false) {
      setShowMultipleOptions(true);
      const existingTitles = fieldSettingsFormik.values.options.map(
        (option) => option.title
      );
      const newTextAreaContent = existingTitles.join("\n");
      setNewItem(newTextAreaContent);
      return;
    }

    const lines = newItem.trim().split("\n");
    const nonEmptyLines = lines
      .map((line) => line.trim())
      .filter((line) => line.trim() !== "");

    const newOptions = nonEmptyLines.map((line) => ({
      id: 0,
      idField: model.id,
      title: line.trim(),
    }));

    if (newOptions.length > 0) {
      fieldSettingsFormik.setFieldValue("options", [
        ...fieldSettingsFormik.values.options,
        ...newOptions,
      ]);
    }

    console.log(newItem);
  };

  const addOption = () => {
    setShowMultipleOptions(false);
    if (newItem.trim() !== "") {
      const newOption = { id: 0, idField: model.id, title: newItem.trim() };
      fieldSettingsFormik.setFieldValue("options", [
        ...fieldSettingsFormik.values.options,
        newOption,
      ]);
      setNewItem("");
    }
  };

  const removeOption = (indexToRemove) => {
    const updatedOptions = fieldSettingsFormik.values.options.filter(
      (_, index) => index !== indexToRemove
    );
    fieldSettingsFormik.setFieldValue("options", updatedOptions);
  };

  const handleEditChange = (value, index) => {
    const updatedOptions = [...fieldSettingsFormik.values.options];
    updatedOptions[index] = { ...updatedOptions[index], title: value };
    fieldSettingsFormik.setFieldValue("options", updatedOptions);

    console.log(fieldSettingsFormik.values);
  };

  useEffect(() => {
    fieldSettingsFormik.setValues(model);
  }, [model]);

  return (
    <>
      <Dialog
        visible={isEditModalVisible}
        header={
          <div className="header-title">
            <span className="field-title">{model.viewName}</span>
          </div>
        }
        onHide={closeModal}
        className="database-field-edit-dialog"
      >
        <form
          onSubmit={fieldSettingsFormik.handleSubmit}
          className="dcp-form"
          style={{ paddingBottom: 0, gap: 0 }}
        >
          <div className="edit-field-wrapper">
            <div className="field-settings">
              <div className="form-row content">
                <span className="field-settings-description">
                  <LanguageProvider id={"database.field.setting.description"} />
                </span>

                <InputContainer
                  label={<LanguageProvider id={"database.field.title"} />}
                >
                  <InputText
                    value={fieldSettingsFormik.values.viewName}
                    onChange={(e) => {
                      fieldSettingsFormik.setFieldValue(
                        "viewName",
                        e.target.value
                      );
                    }}
                    placeholder={model.viewName}
                    className={classNames({
                      "p-inputtext-sm": true,
                    })}
                  />
                  <GetFieldSettingsFormErrorMessage name="viewName" />
                </InputContainer>
              </div>

              {model.type === "unique-selection" ||
              model.type === "dropdown" ? (
                <div className="form-row content">
                  <InputContainer
                    label={LanguageProvider({
                      id: "database.add.option.field",
                    })}
                  >
                    {showMultipleOptions === false ? (
                      <>
                        <div className="radio-options-container">
                          {fieldSettingsFormik.values.options.map(
                            (option, index) => (
                              <div
                                key={index}
                                className="radio-options-wrapper"
                              >
                                <div className="radio-option">
                                  <span>{index}</span>
                                  <InputText
                                    className="option-label"
                                    value={option.title}
                                    onChange={(e) =>
                                      handleEditChange(e.target.value, index)
                                    }
                                  />
                                  <div
                                    className="remove-option"
                                    title={LanguageProvider({
                                      id: "field.database.confirm.delete",
                                    })}
                                  >
                                    <Icon
                                      color={"#4b5563"}
                                      icon={"trash-02"}
                                      onClick={() => removeOption(index)}
                                      size={18}
                                    />
                                  </div>
                                </div>
                              </div>
                            )
                          )}
                        </div>
                        <InputText
                          value={newItem}
                          onChange={(e) => {
                            setNewItem(e.target.value);
                          }}
                          placeholder={"Adicionar uma opção"}
                          className={classNames("one-by-one-options", {
                            "p-inputtext-md": true,
                          })}
                        />
                      </>
                    ) : (
                      <InputTextarea
                        value={newItem}
                        onChange={(e) => {
                          setNewItem(e.target.value);
                        }}
                        className={classNames("multiple-options", {
                          "p-inputtext-md": true,
                        })}
                        cols={4}
                      />
                    )}

                    <div className="new-item-radio">
                      <div className="add-multiple-option">
                        <span
                          className="add-multiple-label"
                          onClick={() => {
                            addMultipleOption();
                          }}
                        >
                          <LanguageProvider id="database.add.multiple.option" />
                        </span>
                      </div>
                      <div className="add-one-by-one-option">
                        <span onClick={() => addOption()}>
                          <LanguageProvider id="database.add.one.by.one.option" />
                        </span>
                      </div>
                    </div>
                    <GetFieldSettingsFormErrorMessage name="viewName" />
                  </InputContainer>
                </div>
              ) : null}

              {model.type === "database" && (
                <div className="form-row content">
                  <InputContainer
                    label={
                      <LanguageProvider id="database.field.placeholder.database" />
                    }
                  >
                    <Dropdown
                      value={fieldSettingsFormik.values.typeDatabaseId}
                      options={databaseOptions}
                      optionLabel="name"
                      optionValue="id"
                      filter
                      onChange={(e) => {
                        fieldSettingsFormik.setFieldValue(
                          "typeDatabaseId",
                          e.value
                        );
                      }}
                    />
                    <GetFieldSettingsFormErrorMessage name="viewName" />
                  </InputContainer>
                </div>
              )}

              <div className="form-row content">
                <InputSwitchContainer
                  label={<LanguageProvider id={"database.field.description"} />}
                >
                  <InputSwitch
                    checked={fieldSettingsFormik.values.hasDescription}
                    onChange={(e) =>
                      fieldSettingsFormik.setFieldValue(
                        "hasDescription",
                        e.value
                      )
                    }
                    className={classNames({
                      "p-inputswitch-sm": true,
                    })}
                  ></InputSwitch>
                </InputSwitchContainer>
                {fieldSettingsFormik.values.hasDescription && (
                  <InputTextarea
                    value={fieldSettingsFormik.values.description}
                    placeholder="Digite uma descrição..."
                    onChange={(e) =>
                      fieldSettingsFormik.setFieldValue(
                        "description",
                        e.target.value
                      )
                    }
                    rows={5}
                    cols={30}
                    className={classNames({
                      "p-inputtext-sm": true,
                    })}
                  ></InputTextarea>
                )}
              </div>

              <div className="form-row content">
                <InputSwitchContainer
                  label={<LanguageProvider id={"database.field.help.title"} />}
                >
                  <InputSwitch
                    checked={fieldSettingsFormik.values.hasTextHelp}
                    onChange={(e) =>
                      fieldSettingsFormik.setFieldValue("hasTextHelp", e.value)
                    }
                    className={classNames({
                      "p-inputswitch-sm": true,
                    })}
                  ></InputSwitch>
                </InputSwitchContainer>
                {fieldSettingsFormik.values.hasTextHelp && (
                  <InputTextarea
                    placeholder="Digite um texto de ajuda..."
                    onChange={(e) =>
                      fieldSettingsFormik.setFieldValue(
                        "textHelp",
                        e.target.value
                      )
                    }
                    className={classNames({
                      "p-inputtext-sm": true,
                    })}
                  ></InputTextarea>
                )}
              </div>
              <div className="form-row content">
                <InputSwitchContainer
                  label={
                    <LanguageProvider id={"database.field.required.field"} />
                  }
                >
                  <InputSwitch
                    checked={fieldSettingsFormik.values.required}
                    onChange={(e) =>
                      fieldSettingsFormik.setFieldValue("required", e.value)
                    }
                    className={classNames({
                      "p-inputswitch-sm": true,
                    })}
                  ></InputSwitch>
                </InputSwitchContainer>
              </div>

              {model.type === "short-text" && (
                <div className="form-row content">
                  <InputSwitchContainer
                    label={
                      <LanguageProvider id={"database.field.format.title"} />
                    }
                  >
                    <InputSwitch
                      checked={fieldSettingsFormik.values.hasInputMask}
                      onChange={(e) =>
                        fieldSettingsFormik.setFieldValue(
                          "hasInputMask",
                          e.value
                        )
                      }
                      className={classNames({
                        "p-inputswitch-sm": true,
                      })}
                    ></InputSwitch>
                  </InputSwitchContainer>
                  {fieldSettingsFormik.values.hasInputMask && (
                    <Dropdown
                      value={fieldSettingsFormik.values.inputMaskName}
                      onChange={(e) =>
                        fieldSettingsFormik.setFieldValue(
                          "inputMaskName",
                          e.target.value
                        )
                      }
                      options={textMasks}
                      optionLabel="label"
                      optionValue="name"
                      className={classNames({
                        "p-inputtext-sm": true,
                      })}
                    ></Dropdown>
                  )}
                </div>
              )}

              {model.type === "number" && (
                <div className="form-row content">
                  <InputSwitchContainer
                    label={
                      <LanguageProvider id={"database.field.format.title"} />
                    }
                  >
                    <InputSwitch
                      checked={fieldSettingsFormik.values.hasInputMask}
                      onChange={(e) =>
                        fieldSettingsFormik.setFieldValue(
                          "hasInputMask",
                          e.value
                        )
                      }
                      className={classNames({
                        "p-inputswitch-sm": true,
                      })}
                    ></InputSwitch>
                  </InputSwitchContainer>
                  {fieldSettingsFormik.values.hasInputMask && (
                    <Dropdown
                      value={fieldSettingsFormik.values.inputMaskName}
                      onChange={(e) =>
                        fieldSettingsFormik.setFieldValue(
                          "inputMaskName",
                          e.target.value
                        )
                      }
                      options={numberMasks}
                      optionLabel="label"
                      optionValue="name"
                      className={classNames({
                        "p-inputtext-sm": true,
                      })}
                    ></Dropdown>
                  )}
                </div>
              )}
            </div>

            <div className="field-preview">
              <span className="field-preview-text">
                <Icon icon={"eye"} size={24} color={currentTheme.textPrimary} />
                <span className="field-preview-title">
                  <LanguageProvider id={"database.field.preview.title"} />
                </span>
              </span>
              <div className="generated-preview">
                <div className="generated-field-preview">
                  <div className="header-field">
                    {fieldSettingsFormik.values.required && <span>*</span>}
                    <Icon
                      icon={fieldSettingsFormik.values.iconName}
                      size={14}
                    />
                    {fieldSettingsFormik.values.viewName}
                    {fieldSettingsFormik.values.hasTextHelp && (
                      <Icon
                        icon={"alert-circle"}
                        size={15}
                        color={"#717BBC"}
                        className={"icon-label"}
                      />
                    )}
                  </div>
                  {fieldSettingsFormik.values.hasDescription && (
                    <span className="description-preview">
                      {fieldSettingsFormik.values.description}
                    </span>
                  )}
                  {model.type === "unique-selection" && (
                    <>
                      <div className="radio-options-wrapper">
                        {fieldSettingsFormik.values.options.map(
                          (option, index) => (
                            <div key={index} className="radio-option-preview">
                              {fieldInput()}
                              <span className="option-label">
                                {option.title}
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    </>
                  )}
                  {model.type === "dropdown" ? (
                    <>
                      <div className="radio-options-wrapper">
                        <Dropdown
                          value={fieldSettingsFormik.values.options.idField}
                          options={fieldSettingsFormik.values.options.map(
                            (option) => option.title
                          )}
                          label={fieldSettingsFormik.values.options.title}
                          className={classNames({
                            "p-inputtext-sm": true,
                          })}
                        />
                      </div>
                    </>
                  ) : null}
                  {model.type !== "unique-selection" &&
                  model.type !== "dropdown" ? (
                    <InputText className="form-field-text" disabled />
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="form-row-footer">
            <div className="button-container">
              <Button
                size="small"
                appearance="primary"
                className="p-button p-button-text p-button-plain"
                type="button"
                text
                onClick={closeModal}
              >
                <LanguageProvider id={"gen.cancel.button"} />
              </Button>
              <Button
                size="small"
                appearance="primary"
                className="p-button"
                type="submit"
                loading={submitting}
              >
                <LanguageProvider id={"gen.save.button"} />
              </Button>
            </div>
          </div>
        </form>
      </Dialog>
    </>
  );
};
