import React, { useEffect, useMemo, useRef, useState } from "react";
import LanguageProvider from "~/shared/components/language-provider";
import { useNavigate, useParams } from "react-router-dom";
import {
  createFloorArea,
  deleteFloorArea,
  getFloorArea,
} from "~/services/api/platform/floor-area";
import "./scss/floor-area-edit.scss";
import {
  AreaListModel,
  FloorAreaCreateModel,
  FloorAreaLocation,
  MapItemType,
} from "~/shared/interfaces";
import PageHeader from "~/shared/components/page-header/page-header";
import { useFormik } from "formik";
import {
  Form,
  FormRow,
  InputContainer,
  InputText,
} from "~/shared/components/dcp-form";
import ColorPickerButton from "~/shared/components/dcp-color-picker-button";
import { InputNumber } from "primereact/inputnumber";
import { Button } from "~/shared/components/dcp-button";
import { FloorAreaDraw } from "./components/floorAreaDraw";
import ModalConfirmation from "~/shared/components/modal-confirmation";
import { useToastContext } from "~/context/ToastContext";
import { SeeExampleButton } from "~/shared/components/buttons/see-example-button";
import { OverlayPanel } from "primereact/overlaypanel";
import Icon from "~/shared/components/icons";
import { FloorAreaExample } from "~/theme/media/assets/floor-area-example";
import { Dropdown } from "primereact/dropdown";
import { listAreaMaps } from "~/services/api";

export function FloorAreaEdit(): JSX.Element {
  const { floorAreaId } = useParams();
  const navigate = useNavigate();
  const { showToast } = useToastContext();
  const [isEdit, setIsEdit] = useState<boolean>(false);

  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false);
  const [duplicateConfirmationOpen, setDuplicateConfirmationOpen] =
    useState<boolean>(false);

  const [areas, setAreas] = useState<AreaListModel[]>([]);

  const exampleOp = useRef(null);

  const floorAreaForm = useFormik<FloorAreaCreateModel>({
    initialValues: {
      floorArea: {
        id: 0,
        name: "",
        code: "",
        color: "#ffcf0f",
        width: 0,
        length: 0,
        locationQtd: 0,
        idArea: null,
        rows: 0,
        clientId: null,
        type: MapItemType.Rack,
      },
      locations: [],
    },
    onSubmit: submitForm,
  });

  async function submitForm(values: FloorAreaCreateModel) {
    try {
      const newFloorArea: FloorAreaCreateModel = await createFloorArea(values);
      if (!newFloorArea) throw new Error();

      floorAreaForm.setValues(newFloorArea);
      showToast({
        severity: "success",
        message: <LanguageProvider id="floor.area.saved" />,
      });
      navigate(-1);
    } catch (error) {
      console.error(error);
      showToast({
        severity: "error",
        message: <LanguageProvider id="unhandled.error" />,
      });
    }
  }

  async function onDuplicate() {
    try {
      floorAreaForm.setFieldValue("id", 0);
      floorAreaForm.setFieldValue(
        "name",
        floorAreaForm.values.floorArea.name + " - Novo"
      );
      setDuplicateConfirmationOpen(false);
    } catch (error) {}
  }

  async function onDelete() {
    try {
      if (floorAreaForm.values.floorArea.id > 0) {
        await deleteFloorArea(floorAreaForm.values.floorArea.id);
      }
      showToast({
        severity: "success",
        message: <LanguageProvider id="gen.success" />,
      });
      navigate(-1);
    } catch (error) {
      showToast({
        severity: "error",
        message: <LanguageProvider id="unhandled.error" />,
      });
    }
  }

  async function loadFloorArea(
    floorAreaId: number | string
  ): Promise<FloorAreaCreateModel> {
    try {
      if (floorAreaId === "0") return;

      const data = await getFloorArea(floorAreaId);
      if (data) {
        return data;
      } else {
        return null;
      }
    } catch (error) {
      console.error(error);
    }
  }

  useEffect(() => {
    async function load() {
      if (floorAreaId === "0") return;
      setIsEdit(true);
      const data = await loadFloorArea(floorAreaId);
      floorAreaForm.setValues(data);
    }
    load();
    // adding form into the depency array will cause a dependency loop
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [floorAreaId]);
  //}, [floorAreaId]);

  useEffect(() => {
    floorAreaForm.setFieldValue(
      "locations",
      reMapLocations(floorAreaForm.values)
    );
  }, [floorAreaForm.values.floorArea]);

  useEffect(() => {
    async function load() {
      const _areas = await listAreaMaps();
      setAreas(_areas);
    }

    load();
  }, []);

  function getAreaTotalSize(): string {
    try {
      const height =
        floorAreaForm.values.floorArea.length *
        floorAreaForm.values.floorArea.rows;
      const width =
        floorAreaForm.values.floorArea.width *
        Math.ceil(
          floorAreaForm.values.floorArea.locationQtd /
            floorAreaForm.values.floorArea.rows
        );

      return `${width >= 0 ? width : 0}cm X ${height}cm`;
    } catch (error) {
      console.error(error);
      return "";
    }
  }

  function headerActions() {
    return (
      <SeeExampleButton
        onClick={(e) => {
          if (exampleOp.current) exampleOp.current.toggle(e);
        }}
        disabled={false}
      ></SeeExampleButton>
    );
  }

  return (
    <div className="floor-area-edit">
      <div className="main-content">
        <PageHeader
          titleTemplate={undefined}
          recordsCount={undefined}
          onReturn={() => navigate(-1)}
          actions={headerActions}
          title={
            floorAreaId === "0" ? (
              <LanguageProvider id={"side.menu.floor.area.create"} />
            ) : (
              <LanguageProvider id={"side.menu.floor.area.edit"} />
            )
          }
        />
        <div className="content">
          <div className="form-container">
            <p className="title">
              <LanguageProvider id={"floor.area.delimitation"} />
            </p>
            <Form>
              <FormRow>
                <InputContainer label={<LanguageProvider id="gen.name" />}>
                  <InputText
                    value={floorAreaForm.values.floorArea.name}
                    onChange={(e) =>
                      floorAreaForm.setFieldValue(
                        "floorArea.name",
                        e.target.value
                      )
                    }
                    id={undefined}
                    name={undefined}
                    onBlur={undefined}
                    style={undefined}
                    placeholder={undefined}
                    disabled={undefined}
                    readOnly={undefined}
                    type={undefined}
                  />
                </InputContainer>
                <InputContainer
                  label={<LanguageProvider id="gen.color" />}
                  className="color-input-container"
                >
                  <ColorPickerButton
                    color={floorAreaForm.values.floorArea.color}
                    onChange={(color) =>
                      floorAreaForm.setFieldValue("floorArea.color", color)
                    }
                  />
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer label={<LanguageProvider id="gen.code" />}>
                  <InputText
                    value={floorAreaForm.values.floorArea.code}
                    onChange={(e) =>
                      floorAreaForm.setFieldValue(
                        "floorArea.code",
                        e.target.value
                      )
                    }
                    id={undefined}
                    name={undefined}
                    onBlur={undefined}
                    style={undefined}
                    placeholder={undefined}
                    disabled={undefined}
                    readOnly={undefined}
                    type={undefined}
                  />
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer
                  label={<LanguageProvider id="product.drone.area" />}
                >
                  <Dropdown
                    options={areas}
                    optionLabel={"name"}
                    optionValue={"id"}
                    filter
                    value={floorAreaForm.values.floorArea.idArea}
                    onChange={(e) =>
                      floorAreaForm.setFieldValue("floorArea.idArea", e.value)
                    }
                  />
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer label={<LanguageProvider id="gen.width" />}>
                  <InputNumber
                    value={floorAreaForm.values.floorArea.width}
                    onChange={(e) =>
                      floorAreaForm.setFieldValue("floorArea.width", e.value)
                    }
                    suffix=" cm"
                    min={0}
                    useGrouping={false}
                    disabled={isEdit}
                  />
                </InputContainer>
                <InputContainer label={<LanguageProvider id="gen.length" />}>
                  <InputNumber
                    suffix=" cm"
                    useGrouping={false}
                    min={0}
                    value={floorAreaForm.values.floorArea.length}
                    onChange={(e) =>
                      floorAreaForm.setFieldValue("floorArea.length", e.value)
                    }
                    disabled={isEdit}
                  />
                </InputContainer>
              </FormRow>
              <FormRow>
                <InputContainer
                  label={<LanguageProvider id="gen.locations.qtd" />}
                >
                  <InputNumber
                    value={floorAreaForm.values.floorArea.locationQtd}
                    onChange={(e) =>
                      floorAreaForm.setFieldValue(
                        "floorArea.locationQtd",
                        e.value
                      )
                    }
                    useGrouping={false}
                    min={0}
                    disabled={isEdit}
                  />
                </InputContainer>
                <InputContainer label={<LanguageProvider id="gen.rows" />}>
                  <InputNumber
                    useGrouping={false}
                    min={0}
                    max={floorAreaForm.values.floorArea.locationQtd}
                    value={floorAreaForm.values.floorArea.rows}
                    onChange={(e) =>
                      floorAreaForm.setFieldValue("floorArea.rows", e.value)
                    }
                    disabled={isEdit}
                  />
                </InputContainer>
              </FormRow>
            </Form>
          </div>
          <div className="preview-container">
            <div className="preview-info">
              <p className="title">
                <LanguageProvider id="floor.area.top.view" />
              </p>
              <p className="info">
                <LanguageProvider id="floor.area.total.size" />{" "}
                {getAreaTotalSize()}
              </p>
            </div>
            <FloorAreaDraw
              floorArea={floorAreaForm.values.floorArea}
              locations={floorAreaForm.values.locations}
              onLocationNameChange={(location) => {
                var newLocations = floorAreaForm.values.locations;
                newLocations.forEach((x) => {
                  if (
                    x.row === location.row &&
                    x.position === location.position
                  )
                    x.name = location.name;
                });
                floorAreaForm.setFieldValue("locations", newLocations);
              }}
            />
          </div>
        </div>
        <div className="footer">
          <div className="left">
            <Button
              severity="danger"
              onClick={() => setDeleteConfirmationOpen(true)}
              disabled={floorAreaForm.values.floorArea.id === 0}
            >
              <LanguageProvider id="gen.exclude.button" />
            </Button>
          </div>
          <div className="right">
            <Button
              type="button"
              className="p-button-secondary "
              onClick={() => setDuplicateConfirmationOpen(true)}
              disabled={floorAreaForm.values.floorArea.id === 0}
            >
              <LanguageProvider id="gen.message.duplicate" />
            </Button>
            <Button
              onClick={async () => await floorAreaForm.submitForm()}
              type="button"
            >
              <LanguageProvider id="gen.save.button" />
            </Button>
          </div>
        </div>
      </div>

      {/* Example overlay panel */}
      <OverlayPanel
        ref={exampleOp}
        appendTo={"self"}
        className="example-op"
        draggable
      >
        <div className="title">
          <span>
            <LanguageProvider id="floor.area.example" />
          </span>
          <Icon icon={"move"} size={undefined} color={undefined} />
        </div>
        <FloorAreaExample />
        <Button>OK</Button>
      </OverlayPanel>

      <ModalConfirmation
        isDelete={true}
        isOpen={deleteConfirmationOpen}
        modalTitle={<LanguageProvider id="gen.exclude.button" />}
        bodyMessage={
          <LanguageProvider id="gen.message.confirm.delete.message" />
        }
        onConfirm={onDelete}
        onCancel={() => setDeleteConfirmationOpen(false)}
      ></ModalConfirmation>

      <ModalConfirmation
        isOpen={duplicateConfirmationOpen}
        modalTitle={<LanguageProvider id="floor.area.duplicate" />}
        bodyMessage={<LanguageProvider id="floor.area.duplicate.msg" />}
        isDelete={false}
        onConfirm={onDuplicate}
        onCancel={() => setDuplicateConfirmationOpen(false)}
      />
    </div>
  );
}

function reMapLocations(floorArea: FloorAreaCreateModel): FloorAreaLocation[] {
  var locations: FloorAreaLocation[] = [];

  let rows = floorArea.floorArea.rows;
  let locationsQtd = floorArea.floorArea.locationQtd;
  let locationsPerRow = Math.ceil(locationsQtd / rows);
  let extraRowLocations = locationsQtd % locationsPerRow;

  let createdLocations = 0;

  for (let i = 0; i < rows; i++) {
    if (extraRowLocations > 0 && i + 1 === rows) {
      for (let j = 0; j < extraRowLocations; j++) {
        const exisitingLocation = floorArea.locations.find(
          (x) => x.row === i && x.position === j
        );
        if (exisitingLocation) {
          locations.push(exisitingLocation);
        } else {
          locations.push({
            id: 0,
            name: `${floorArea.floorArea.code} - ${createdLocations + 1}`,
            floorAreaId: floorArea.floorArea.id,
            row: i,
            position: j,
          });
        }

        createdLocations++;
      }
      continue;
    }

    for (let j = 0; j < locationsPerRow; j++) {
      const exisitingLocation = floorArea.locations.find(
        (x) => x.row === i && x.position === j
      );
      if (exisitingLocation) {
        locations.push(exisitingLocation);
      } else {
        locations.push({
          id: 0,
          name: `${floorArea.floorArea.code} - ${createdLocations + 1}`,
          floorAreaId: floorArea.floorArea.id,
          row: i,
          position: j,
        });
      }

      createdLocations++;
    }
  }

  console.log(locations);
  return locations;
}
