import "./ListOrdersChart.scss";
import settings from "~/services/settings.json";
import React, { useContext, useEffect, useState } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { ProgressBar } from "primereact/progressbar";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { propertyFrommStorage } from "~/services/storage/storage-access";

export default function ListOrdersChart() {
  const dcpAxiosService = useDcpAxiosService();
  const useMockData = useContext(UseMockDataContext);
  const [expandedRows, setExpandedRows] = useState(null);
  // const [statusDaysFilter, setStatusDaysFilter] = useState(0); - Filtro de dias
  const fakeOrders = [
    {
      orderId: "145887626661248",
      repackCount: "4/7",
      status: 2,
      orderDetails: [
        {
          location: "G1",
          repack: "REPACKPT0013288",
          entrance: "10/03/2021 14:05",
        },
        {
          location: "G1",
          repack: "REPACKPT0013269",
          entrance: "10/03/2021 14:10",
        },
        {
          location: "G1",
          repack: "REPACKPT0013241",
          entrance: "10/03/2021 14:23",
        },
      ],
    },
    {
      orderId: "145887626661249",
      repackCount: "5/5",
      status: 1,
      orderDetails: [
        {
          location: "G2",
          repack: "REPACKPT0014000",
          entrance: "11/03/2021 12:00",
        },
      ],
    },
    {
      orderId: "145887626661250",
      repackCount: "6/10",
      status: 7,
      orderDetails: [
        {
          location: "G3",
          repack: "REPACKPT0015000",
          entrance: "12/03/2021 09:00",
        },
      ],
    },
  ];
  const [orders, setOrders] = useState([]);
  const [late, setLate] = useState(0);
  const [warning, setWarning] = useState(0);
  const applicationClientId = propertyFrommStorage(
    "authentication",
    "applicationId"
  );

  const getConfigs = async () => {
    try {
      const warningDaysResponse = await dcpAxiosService.get(
        settings.Urls.Rest.Settings + "/get-spesific-setting",
        "Platform",
        {
          params: {
            applicationClientId: applicationClientId,
            setting: "order_days_warning"
          }
        }
      );

      const lateDaysResponse = await dcpAxiosService.get(
        settings.Urls.Rest.Settings + "/get-spesific-setting",
        "Platform",
        {
          params: {
            applicationClientId: applicationClientId,
            setting: "order_days_late"
          }
        }
      );

      setLate(Number(lateDaysResponse.data.data.value));
      setWarning(Number(warningDaysResponse.data.data.value));

    } catch (error) {
      console.error("Erro ao buscar configurações: ", error);
    }
  };

  const statusTemplate = (rowData) => {
    let statusColor = "";
    const statusDay = rowData.status > 1 ? "dias" : "dia";
    if (rowData.status <= warning) {
      statusColor = "#a4e1df";
    } else if (rowData.status <= late && rowData.status > warning) {
      statusColor = "#FAE5BA";
    } else {
      statusColor = "#F8BAC0";
    }

    return (
      <span
        className="status-badge"
        style={{
          backgroundColor: statusColor,
        }}
      >
        {rowData.status} {statusDay}
      </span>
    );
  };

  const repackCountTemplate = (rowData) => {
    const [completed, total] = rowData.repackCount.split("/").map(Number);
    const progress = ((completed / total) * 100).toFixed(0);

    return (
      <div className="repack-count-wrapper">
        <ProgressBar
          value={progress}
          className="progress-bar-repack"
          showValue={false}
        ></ProgressBar>
        <span className="days-repack-count">{rowData.repackCount}</span>
      </div>
    );
  };

  const detailsTemplate = (rowData) => {
    return (
      <div style={{ padding: "1rem" }}>
        <h4>Detalhes do Pedido {rowData.orderId}</h4>
        <DataTable
          className="list-order-details-sub-table"
          value={rowData.orderDetails}
        >
          <Column
            body={(rowData) => {
              return (
                <div className="location-wrapper">
                  <span className="location-title">{rowData.location}</span>
                </div>
              );
            }}
            header="Ubicación"
          ></Column>
          <Column field="repack" header="Repack"></Column>
          <Column
            body={(rowData) => {
              return (
                <div className="entrance-wrapper">
                  <span className="entrance-title">
                    {new Date(rowData.entrance)
                      .toLocaleString("pt-BR", {
                        day: "2-digit",
                        month: "2-digit",
                        year: "numeric",
                        hour: "2-digit",
                        minute: "2-digit",
                      })
                      .replace(",", "")}
                  </span>
                </div>
              );
            }}
            header="Entrada"
          ></Column>
        </DataTable>
      </div>
    );
  };

  useEffect(() => {
    getConfigs();
    if (useMockData) {
      setOrders(fakeOrders);
    } else {
      const handleGetAllocatedOrdersDetailed = async () => {
        try {
          const { data, status } = await dcpAxiosService.get(
            settings.Urls.Rest.DashboardItem + "/list-allocated-orders-details",
            "StageControl"
          );
          if (status === 200) {
            setOrders(data.data);
          }
        } catch (error) {
          console.error(error);
        }
      };

      handleGetAllocatedOrdersDetailed();
    }
  }, [useMockData]);

  return (
    <div className="list-order-container">
      <DataTable
        className="list-order-table"
        value={orders}
        rowExpansionTemplate={detailsTemplate}
        expandedRows={expandedRows}
        onRowToggle={(e) => setExpandedRows(e.data)}
      >
        <Column expander style={{ width: "3em" }}></Column>
        <Column field="orderId" header="Order ID"></Column>
        <Column body={repackCountTemplate} header="Repack Count"></Column>
        <Column body={statusTemplate} header="Status"></Column>
      </DataTable>
    </div>
  );
}
