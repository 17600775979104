import { Navigate, Outlet } from "react-router-dom";

import { UserContext } from "./app";
import { useContext } from "react";
import { useLocation } from "react-router";

const ProtectedRoutes = () => {
  const { user } = useContext(UserContext);
  const location = useLocation();

  if (user) {
    return <Outlet />;
  }
  return <Navigate to="auth/login" replace state={{ from: location }} />;
};

export default ProtectedRoutes;
