import "./AndonErrors.scss";
import LanguageProvider from "~/shared/components/language-provider";
import React, { useContext, useEffect, useState } from "react";
import { getOperationAlerts } from "~/services/api/sequence/andon";
import { OperationAlert } from "~/shared/interfaces/sequence.ts";
import { AndonSettingsContext } from "~/pages/sequence/andon/context/AndonDashboardSettingsContext.tsx";

export default function AndonErrors() {
  const [alerts, setAlerts] = useState<OperationAlert[]>([]);
  const settings = useContext(AndonSettingsContext);

  const errors = [
    {
      id: 1,
      message: "LOREM IPSUM DOLOR SIT AMET",
      time: "01/01/24 12:00",
      severity: "error",
    },
    {
      id: 2,
      message:
        "LOREM IPSUM DOLOR SIT AMET AM ILLUM DOLORUM RERUM DOLOR SIT AMET",
      time: "01/01/24 8:30",
      severity: "warning",
    },
    {
      id: 3,
      message: "LOREM IPSUM DOLOR SIT AMET",
      time: "01/01/24 8:12",
      severity: "warning",
    },
  ];

  async function loadAlerts() {
    const alerts = await getOperationAlerts();
    setAlerts(alerts);
  }

  // useEffect(() => {
  //   var intervalId = setInterval(
  //     loadAlerts,
  //     settings?.refreshIntervalSeconds
  //       ? settings.refreshIntervalSeconds * 1000
  //       : 10 * 1000
  //   );
  //   return () => {
  //     clearInterval(intervalId);
  //   };
  // }, [settings.refreshIntervalSeconds]);

  useEffect(() => {
    loadAlerts();
  }, []);

  return (
    <div className="andon-errors">
      <div className="header">
        <span className="title">
          <LanguageProvider id="dashboard.andon.errors" />
        </span>
      </div>
      <div className="error-list">
        {alerts.length > 0 &&
          alerts.map((alert, index) => {
            return (
              <span
                key={index}
                className={`error-line severity-${alert.severity}`}
              >
                {new Date(alert.createdAt).toLocaleTimeString() +
                  " - " +
                  alert.message}
              </span>
            );
          })}
      </div>
    </div>
  );
}
