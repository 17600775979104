import "../scss/massive-print-dialog.scss";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Dropdown } from "primereact/dropdown";
import React, { useEffect, useState } from "react";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import LanguageProvider from "~/shared/components/language-provider";
import settings from "~/services/settings.json";
import qs from "qs";
import { propertyFrommStorage } from "~/services/storage/storage-access";
import { GetPrinters } from "~/services/api";
import { InventoryPrintConnectionModel } from "~/shared/interfaces";

export const MassivePrintDialog = ({
  isOpen,
  onClose,
  massivePrintDataSelected,
}) => {
  const axiosService = useDcpAxiosService();
  const applicationClientId = propertyFrommStorage(
    "authentication",
    "applicationId"
  );
  // Loading
  const [loading, setLoading] = useState(false);
  const [disablePrintButton, setDisablePrintButton] = useState(true);
  const [interfacePrintData, setInterfacePrintData] = useState([]);
  const [printDriverValueInSettings, setPrintDriverValueInSettings] =
    useState("");
  const [printerSelected, setPrinterSelected] =
    useState<InventoryPrintConnectionModel>();

  const [printers, setPrinters] = useState<InventoryPrintConnectionModel[]>([]);

  const handleMassivePrint = async () => {
    try {
      const selectedPrinter = printerSelected.name;
      const printerValuesSelected = printerSelected;

      const returnDataToMassivePrint = {
        printer: selectedPrinter,
        printerValues: printerValuesSelected,
        interfacePrintData: interfacePrintData,
        printDriverValueInSettings: printDriverValueInSettings,
      };

      massivePrintDataSelected(returnDataToMassivePrint);
      onClose();
    } catch (error) {
      console.error(error);
    }
  };

  function findSettingsValueByGroupName(
    settingsResponse,
    groupName,
    subgroupName,
    propertyName
  ) {
    return settingsResponse.data.data
      .find((group) => group.name === groupName)
      ?.subgroups.find((subgroup) => subgroup.name === subgroupName)
      ?.settings.find((setting) => setting.propertyName === propertyName)
      ?.value;
  }

  const getInterfacePrintData = async () => {
    try {
      setLoading(true);
      const { data: databaseProductProcess } = await axiosService.get(
        `${settings.Urls.Rest.DatabasesProductProccess}/get-database-product-process`,
        "Platform",
        {
          params: {
            databaseProductProcessType:
              settings.DatabaseProcessType.Inventory_IdentificationInterface,
          },
        }
      );
      const { data, status } = await axiosService.get(
        settings.Urls.Rest.DatabaseItem + "/list-database-items",
        "Platform",
        {
          params: {
            idDatabase: databaseProductProcess.data.idDatabase,
            databaseName: databaseProductProcess.data.nameDatabase,
            getAllRows: true,
          },
        }
      );
      const settingsResponse = await axiosService.get(
        settings.Urls.Rest.Settings + "/list-settings",
        "Platform",
        {
          params: {
            applicationClientId: applicationClientId,
          },
        }
      );

      // Printer interface data
      const columnNameValueInSettings = findSettingsValueByGroupName(
        settingsResponse,
        "interface-impressao",
        "inventory-interface-impressao-fields",
        "fields-print-interface-sku"
      );
      const columnCodeValueInSettings = findSettingsValueByGroupName(
        settingsResponse,
        "interface-impressao",
        "inventory-interface-impressao-fields",
        "fields-print-interface-pallet"
      );

      // Printer driver url
      const printerDriverValueInSettings = findSettingsValueByGroupName(
        settingsResponse,
        "interface-impressao",
        "inventory-interface-impressao",
        "fields-print-driver"
      );

      setPrintDriverValueInSettings(printerDriverValueInSettings);

      if (status === 200) {
        const codeColumnName = data.data?.headers?.find(
          (column) => column.columnName === columnCodeValueInSettings
        ).columnKey;
        const labelColumnName = data.data?.headers?.find(
          (column) => column.columnName === columnNameValueInSettings
        ).columnKey;

        const formattedValues = data.data?.items.map((item) => ({
          name: item.columns[labelColumnName],
          value: item.columns[codeColumnName],
        }));
        setInterfacePrintData(formattedValues);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleChangeSkuDropdown = async (e) => {
    const valueSelected = e.value;
    if (valueSelected) {
      setPrinterSelected(valueSelected);
      setDisablePrintButton(false);
    }
  };

  useEffect(() => {
    async function getPrinters() {
      setLoading(true);
      setPrinters(await GetPrinters());
      setLoading(false);
    }

    getPrinters();
    getInterfacePrintData();
  }, []);

  return (
    <Dialog
      visible={isOpen}
      onHide={onClose}
      className="massive-print-dialog"
      headerStyle={{ padding: "20px" }}
      contentStyle={{
        paddingTop: "20px",
        borderBottom: "1px solid var(--systemBackground)",
        borderTop: "1px solid var(--systemBackground)",
        padding: "30px 0px 0px 0px",
      }}
      header={
        <>
          <span className="dashboards-header">
            <LanguageProvider id={"gen.massive.identification.print"} />
          </span>
        </>
      }
      onClick={(e) => e.stopPropagation()}
    >
      <div className="massive-print-selected-option">
        <div className="massive-print-selected-form-wrapper">
          <Form>
            <FormRow>
              <InputContainer
                label={
                  <LanguageProvider
                    id={"inventory.print.identification.print.massive.printer"}
                  />
                }
              >
                <Dropdown
                  id="printer"
                  placeholder={LanguageProvider({
                    id: "gen.placeholder.select",
                  })}
                  options={printers}
                  onChange={handleChangeSkuDropdown}
                  value={printerSelected}
                  optionLabel="name"
                  loading={loading}
                />
              </InputContainer>
            </FormRow>
          </Form>
        </div>
        <div className="modal-footer-wrapper">
          <div className="modal-footer">
            <div className="dcp-btn-wrapper-manage-modal">
              <Button
                label={LanguageProvider({ id: "gen.cancel" })}
                className="p-button p-button-text p-button-plain"
                onClick={() => {
                  onClose();
                }}
              />
              <Button
                label={LanguageProvider({ id: "gen.print.button" })}
                onClick={handleMassivePrint}
                type="submit"
                className="p-button"
                disabled={disablePrintButton}
                autoFocus
              />
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
};
