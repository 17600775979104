import settings from "~/services/settings.json";
import DcpAxiosService from "~/services/axios/dcp-axios-service";
import { PrintDocumentPrinterConnectionModel } from "~/shared/interfaces/document-print";

function axiosService(): DcpAxiosService {
  const service = new DcpAxiosService();
  return service;
}

export async function GetPrinters(): Promise<
  PrintDocumentPrinterConnectionModel[]
> {
  try {
    var { data, status } = await axiosService().get(
      settings.Urls.Rest.print + "/printers",
      "PrintDocument"
    );

    if (status === 200 && data.data) {
      return data.data;
    } else {
      return [];
    }
  } catch (error) {
    console.error(error);
  }
}

export async function GetDocCount(model): Promise<number> {
  try {
    var { data, status } = await axiosService().post(
      settings.Urls.Rest.print + "/doc-count",
      model,
      "PrintDocument"
    );

    if (status === 200 && data.data) {
      return data.data;
    } else {
      return 0;
    }
  } catch (error) {
    console.error(error);
  }
}
