import React, { useEffect, useState } from "react";
import InitialSetup from "~/pages/shared-modules/dashboard-graphs/components/initial-setup/Initial-setup";
import DashboardGraphs from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service";
import settings from "~/services/settings.json";

const Home = () => {
  const dcpAxiosService = useDcpAxiosService();
  const [mainDashboards, setMainDashboards] = useState([]);

  const getMainDashboards = async () => {
    const dashboardResponse = await dcpAxiosService.get(
      settings.Urls.Rest.Dashboard + "/list",
      "Platform",
      {
        params: {
          showMaindashboards: true,
        },
      }
    );
    if (dashboardResponse.status === 200) {
      setMainDashboards(dashboardResponse.data.data);
    } else {
      console.error("Error loading main dashboards");
    }
  };

  useEffect(() => {
    getMainDashboards();
  }, []);

  return (
    <>
      {mainDashboards.length > 0 ? (
        <div className="home">
          <DashboardGraphs previewMode={true} isMain={true} />
        </div>
      ) : (
        <InitialSetup />
      )}
    </>
  );
};

export default Home;
