import "./scss/command-modal.scss";

import { useRef, useState } from "react";

import { InputText } from "primereact/inputtext";
import LanguageProvider from "~/shared/components/language-provider";
import { Menu } from "primereact/menu";
import { useDcpAxiosService } from "~/services/axios/dcp-axios-service.tsx";
import { Form, FormRow, InputContainer } from "~/shared/components/dcp-form";
import { Button } from "~/shared/components/dcp-button";
import settings from "~/services/settings.json";
import { useDebounce } from "primereact/hooks";
import { useEffect } from "react";
import axios from "axios";
import { useToastContext } from "~/context/ToastContext";
import AnsiToHtml from "ansi-to-html";

const CommandModalPicking = () => {
    const dcpAxiosService = useDcpAxiosService();
    const [command, setCommand] = useState("");
    const [log, setLog] = useState("");
    const commandRef = useRef(null);
    const { showToast } = useToastContext();

    const api = axios.create({
        headers: { "content-type": "application/json" },
        baseURL: "http://localhost:5127/api/telnet/wms/core",
    });

    async function SendCommand(ansiCommand, commandToSend) {
        try {
            const { data, status } = await api.post("/auth/write-command", {
                speed: 4000,
                userName: "BERNASCONF",
                password: "v98SIICevb4359",
                wmsHostName: "matrix-wms-02U",
                wmsPort: "45908",
                command: commandToSend,
                ansiCommand: ansiCommand
            });

            if (status === 200) {
                setLog(data.log);

                if (!data.status) {
                    showToast({
                        severity: "error",
                        message: data.message,
                    });
                    setCommand("");
                    commandRef.current.focus();
                    return;
                }
            }
        } catch (error) {
            console.error(error);
            showToast({
                severity: "error",
                message: error,
            });
        }
    }

    async function onBlurCommand(e) {
        try {
            if (e.code !== "Tab" && e.code !== "Enter") return;
            e.preventDefault();
            if (command.length === 0) return;

            SendCommand(false, command);
        } catch (error) {
            console.error(error);
            showToast({
                severity: "error",
                message: error,
            });
        }
    }

    async function readCommand() {
        try {
            const { data, status } = await api.post("/auth/read-screen", {
                speed: 0,
                userName: "BERNASCONF",
                password: "v98SIICevb4359",
                wmsHostName: "matrix-wms-02U",
                wmsPort: "45908",
                command: "",
                ansiCommand: false
            });

            if (status === 200) {
                console.log(log);
                setLog(data.log);

                if (!data.status) {
                    showToast({
                        severity: "error",
                        message: data.message,
                    });
                    setCommand("");
                    commandRef.current.focus();
                    return;
                }
            }
        } catch (error) {
            console.error(error);
            showToast({
                severity: "error",
                message: error,
            });
        }
    }

    useEffect(() => {
        readCommand();
      }, []);

    return (
        <>
            <div className="command-modal-picking">
                <div className="content">
                    <div className="send-command">
                        <Form>
                            <FormRow>
                                <InputContainer
                                    label={<LanguageProvider id="dcp.wms.core.command" />}
                                >
                                    <InputText
                                        value={command}
                                        onChange={(e) => setCommand(e.target.value)}
                                        ref={commandRef}
                                        onKeyDown={onBlurCommand}
                                    ></InputText>
                                </InputContainer>
                            </FormRow>
                        </Form>
                        <div className="command-buttons">

                            <Button appearance="primary" onClick={() => SendCommand(true, "Tab")}>
                                Tab
                            </Button>
                            <Button appearance="primary" onClick={() => SendCommand(true, "F10")}>
                                F10
                            </Button>
                            <Button appearance="primary" onClick={() => SendCommand(true, "Enter")}>
                                Enter
                            </Button>
                        </div>
                    </div>
                    <div className="view-response">
                        <label class="title">WMS Core</label>
                        <div className="html-response">
                            <div dangerouslySetInnerHTML={{ __html: log }} />
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <div className="items">
                        <div className="item">
                            <span className="tag">Esc</span>{" "}
                            <LanguageProvider id={"dpc.gen.leave.key.desc"} />
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default CommandModalPicking;
