import "./AndonWaitingShippingOrders.scss";
import { useContext, useEffect } from "react";
import { classNames } from "primereact/utils";
import LanguageProvider from "~/shared/components/language-provider";
import { AndonSettingsContext } from "~/pages/sequence/andon/context/AndonDashboardSettingsContext";

export default function AndonWaitingShippingOrders() {
  const tripNumber = 5344;
  const data = [
    {
      position: 1,
      id: 344,
      partNumber: "34250023",
      read: true,
    },
    {
      position: 2,
      id: 345,
      partNumber: "34250023",
      read: true,
    },
    {
      position: 3,
      id: 346,
      partNumber: "34250068",
      read: false,
    },
    {
      position: 4,
      id: 347,
      partNumber: "34250068",
      read: false,
    },
    {
      position: 5,
      id: 348,
      partNumber: "34250023",
      read: false,
    },
    {
      position: 6,
      id: 345,
      partNumber: "34250068",
      read: true,
    },
    {
      position: 7,
      id: 346,
      partNumber: "34250068",
      read: false,
    },
    {
      position: 8,
      id: 347,
      partNumber: "34250023",
      read: false,
    },
    {
      position: 9,
      id: 348,
      partNumber: "34250023",
      read: false,
    },
    {
      position: 10,
      id: 345,
      partNumber: "34250068",
      read: true,
    },
    {
      position: 11,
      id: 346,
      partNumber: "34250023",
      read: false,
    },
    {
      position: 12,
      id: 347,
      partNumber: "34250068",
      read: false,
    },
    {
      position: 13,
      id: 348,
      partNumber: "34250068",
      read: false,
    },
    {
      position: 14,
      id: 345,
      partNumber: "34250068",
      read: true,
    },
    {
      position: 15,
      id: 346,
      partNumber: "34250068",
      read: false,
    },
    {
      position: 16,
      id: 347,
      partNumber: "34250068",
      read: false,
    }
  ];

  const settings = useContext(AndonSettingsContext);

  return (
    <div className="andon-waiting-shipping-orders">
      <div className="header">
        <span className="title">
          <LanguageProvider id="gen.trip" /> {tripNumber}
        </span>
      </div>
      <div className="content-table">
        {data.map((item, index) => {
          return (
            <div
              className={classNames("line", {
                read: item.read,
              })}
              style={{
                background: item.read
                  ? settings.validatedPositionColor
                  : "unset",
              }}
              index={item.id + index}
            >
              <span className="position">{item.position}</span>
              <span className="id">{item.id}</span>
              <span className="part-number">{item.partNumber}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
}
