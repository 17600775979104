export interface WorkstationConnectionModel {
  name: string;
  protocol: string;
  address: string;
  port: string;
  user: string;
  wmsPort: string;
  password: string;
  wmsHostname: string;
}

export interface TelnetResponse {
  log: string;
  status: boolean;
  reprocessa: boolean;
  deslogado: boolean;
}

export interface TelnetUser {
  speed: number;
  userName: string;
  password: string;
  wmsHostName: string;
  wmsPort: number;
  nf: string;
  serial: string;
  sku: string;
  menuCode: string;
}

export interface CevaCorePickingData {
  id: number;
  applicationClientId: string;
  serialNumber: string;
  nf: string;
  sku: string;
  userName: string;
  eventDate: Date;
  codigoEstacao: string;
  reprocessed: boolean;
  hidden: boolean;
  log: string;
  status: CevaCorePickingStatus;
}

export enum CevaCorePickingStatus {
  Pending,
  Processed,
  Error,
  Reprocess,
}

export interface HideItemsModel {
  itemIds: number[];
  batchName: string;
}
