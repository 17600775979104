import { Chart } from "primereact/chart";
import { useContext, useEffect, useState } from "react";
import { UseMockDataContext } from "~/pages/shared-modules/dashboard-graphs/dashboard-graphs";
import { defaultChartOptions } from "../../../../../../../shared-modules/dashboard-graphs/components/grid-item/items/grid-item-helpers";
import fakeData from "../../fake-data/sequence/errors-by-user.json";
import { ThemeContext } from "~/app";

export default function ErrosByUser(configs) {
  const { currentTheme } = useContext(ThemeContext);
  const useMockData = useContext(UseMockDataContext);

  const [data, setData] = useState({ labels: [], datasets: [] });

  useEffect(() => {
    if (useMockData) {
      setData(fakeData);
    } else {
      setData({ labels: [], datasets: [] });
    }
  }, [useMockData]);

  const options = {
    ...defaultChartOptions,
    cutout: "50%",
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          color: currentTheme.dashboardDataColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
      y: {
        ticks: {
          color: currentTheme.dashboardDataColor,
        },
        grid: {
          color: currentTheme.dashboardStrokesColor,
        },
      },
    },
    indexAxis: "y",
  };

  return (
    <Chart
      type="bar"
      options={options}
      data={data}
      width="100%"
      height="100%"
    />
  );
}
